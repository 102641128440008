import { Alert, FormControl, FormLabel, Typography } from '@mui/material';
import { useApplication } from 'contexts/ApplicationContext';
import { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { FormBuilderFieldType } from '../types/FormBuilderFieldType.enum';
import { useGet } from 'hooks/useGet';
import Loader from 'components/Loader';
import { useWindowSize } from 'hooks/useWindowSize';
import { ResponseEnvelope } from 'types/ResponseEnvelope';

interface ZohoSignFrameFieldProps {
  id: string;
  label: string;
  disabled: boolean;
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const ZohoSignFrameField = ({ id: fieldId, label, disabled, error, name, readOnly }: ZohoSignFrameFieldProps) => {
  const [iframeSrcUrl, setIframeSourceUrl] = useState<string | null>(null);
  const [iframeError, setIframeError] = useState<string | null>(null);

  const {
    state: { application },
  } = useApplication();

  const fetchIframeSrcUrl = `/crm/application/${application?.id}/field/${fieldId}/zoho-sign-link`;
  const [isIframeSrcUrlLoading, getIframeSrcUrl] = useGet<ResponseEnvelope<{ link: string }>>(fetchIframeSrcUrl);
  const [, windowHeight] = useWindowSize();

  useEffect(() => {
    getIframeSrcUrl()
      .then((response) => setIframeSourceUrl(response.data.link))
      .catch((error: Error) => setIframeError(error.message));
  }, [getIframeSrcUrl]);

  if (isIframeSrcUrlLoading) return <Loader fullPage />;

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type={FormBuilderFieldType.ZohoSignFrame}>
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        {(disabled || readOnly) && (
          <Alert severity="info" variant="outlined">
            Signing this document is disabled
          </Alert>
        )}
        {iframeError && (
          <Alert severity="error" variant="outlined">
            {iframeError}
          </Alert>
        )}
        {iframeSrcUrl && (
          <iframe
            title={name}
            src={iframeSrcUrl!}
            width="100%"
            height={windowHeight * 0.7} // 70vh
          />
        )}
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

export default ZohoSignFrameField;
