import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

const invoiceAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/invoices{/id}{/action}`,
});

const api = {
  GET: invoiceAPI.wrap(defaultRequest, { method: 'GET' }),
  POST: invoiceAPI.wrap(defaultRequest, { method: 'POST' }),
};

export default api;
