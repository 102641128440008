import { useState } from 'react';
import { addDays, addMonths, addWeeks, addYears, isValid, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { DatePicker } from '@mui/x-date-pickers';
import { DatePickerDeltaDirection, DatePickerPeriod } from '../types/api/ApiFormField';

interface DatePickerFieldProps {
  name: string;
  label: string;
  disabled: boolean;
  value: Date;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  readOnly: boolean;
  min?: number;
  minPeriod?: DatePickerPeriod;
  minDirection?: DatePickerDeltaDirection;
  max?: number;
  maxPeriod?: DatePickerPeriod;
  maxDirection?: DatePickerDeltaDirection;
  message?: string;
  messageComparison?: string;
  messageAmount?: number;
  messagePeriod?: DatePickerPeriod;
  messageIsAfter?: boolean;
}

export const DatePickerField = ({
  disabled,
  value,
  onChange,
  error,
  name,
  label,
  readOnly,
  min,
  minPeriod,
  minDirection,
  max,
  maxPeriod,
  maxDirection,
  message,
  messageComparison,
  messageAmount,
  messagePeriod,
  messageIsAfter,
}: DatePickerFieldProps) => {
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const handleToggleWarningDialog = () => {
    setShowWarningDialog(!showWarningDialog);
    setWarningMessage(message || null);
  };

  const handleChange = (newValue: Date | null, onChange: ControllerRenderProps['onChange']) => {
    if (newValue === null || !isValid(newValue)) return;

    if (message && messageComparison && messageAmount && messagePeriod && messageIsAfter) {
      let comparisonDate = new Date();

      switch (messagePeriod) {
        case 'days':
          comparisonDate = messageIsAfter ? addDays(comparisonDate, messageAmount) : subDays(comparisonDate, messageAmount);
          break;
        case 'weeks':
          comparisonDate = messageIsAfter ? addWeeks(comparisonDate, messageAmount) : subWeeks(comparisonDate, messageAmount);
          break;
        case 'months':
          comparisonDate = messageIsAfter ? addMonths(comparisonDate, messageAmount) : subMonths(comparisonDate, messageAmount);
          break;
        case 'years':
          comparisonDate = messageIsAfter ? addYears(comparisonDate, messageAmount) : subYears(comparisonDate, messageAmount);
          break;
      }

      if ((messageComparison === 'lessthan' && newValue < comparisonDate) || (messageComparison === 'morethan' && newValue > comparisonDate)) {
        handleToggleWarningDialog();
        return;
      }
    }

    onChange(newValue);
  };

  const getMinOrMaxDate = (amount: number, period: DatePickerPeriod, direction: DatePickerDeltaDirection) => {
    const add = direction === 'after';

    switch (period) {
      case 'days':
        return add ? addDays(new Date(), amount) : subDays(new Date(), amount + 1);
      case 'weeks':
        return add ? addWeeks(new Date(), amount) : subWeeks(new Date(), amount + 1);
      case 'months':
        return add ? addMonths(new Date(), amount) : subMonths(new Date(), amount + 1);
      case 'years':
        return add ? addYears(new Date(), amount) : subYears(new Date(), amount + 1);
    }
  };

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="date-picker">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <DatePicker
          aria-labelledby={`${name}-label`}
          value={value}
          readOnly={disabled || readOnly}
          slotProps={{
            textField: { size: 'small', error: !!error },
          }}
          onChange={(newValue) => handleChange(newValue, onChange)}
          minDate={min && minPeriod && minDirection ? getMinOrMaxDate(min, minPeriod, minDirection) : undefined}
          maxDate={max && maxPeriod && maxDirection ? getMinOrMaxDate(max, maxPeriod, maxDirection) : undefined}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}

      <Dialog open={showWarningDialog} onClose={handleToggleWarningDialog}>
        <DialogTitle variant="h4" component="h2">
          Warning
        </DialogTitle>
        <DialogContent>
          <Typography>{warningMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleWarningDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DatePickerField;
