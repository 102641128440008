import { useMemo, useState } from 'react';
import HasAccess from 'permissions/HasAccess';
import RestoreIcon from '@mui/icons-material/Restore';
import { PERMISSION_CREATE_INVOICE } from 'permissions/constants/Roles';
import { Box, Button, FormControl, FormLabel, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface TemplateOptionsListProps {
  templates: Map<string, any>;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (id: string) => void;
  onAddRow: () => void;
}

export const TemplateOptionsList = ({ templates, disabled, readOnly, onChange, onAddRow }: TemplateOptionsListProps) => {
  const [value, setValue] = useState('');
  const options = useMemo(
    () =>
      Array.from(templates.values()).map((template) => {
        return {
          value: template.id,
          total: template.total,
          label: template.name,
        };
      }),
    [templates],
  );

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleRestore = () => {
    onChange(value);
  };

  return (
    <Stack justifyContent="space-between" gap={1} mb={2}>
      <FormControl fullWidth data-cy="invoice-templates-list">
        <FormLabel id="invoice-templates-label">Invoice template</FormLabel>
        <Select
          size="small"
          aria-labelledby="invoice-templates-label"
          value={value}
          displayEmpty
          disabled={disabled}
          readOnly={readOnly}
          onChange={(event) => handleChange(event.target.value)}
        >
          <MenuItem value="">Select invoice template</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Box>
                <Typography>{option.label}</Typography>
                {option.total ? <Typography sx={{ fontSize: '0.7em' }}>{option.total}</Typography> : <></>}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction="row" gap={1}>
        <HasAccess for="permission" name={[PERMISSION_CREATE_INVOICE]}>
          <Tooltip title="Restore">
            <IconButton disabled={disabled} onClick={handleRestore}>
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        </HasAccess>
        {!disabled && (
          <HasAccess for="permission" name={[PERMISSION_CREATE_INVOICE]}>
            <Button startIcon={<AddIcon />} onClick={onAddRow}>
              New line
            </Button>
          </HasAccess>
        )}
      </Stack>
    </Stack>
  );
};
