import { store } from 'app/store';
import { Provider } from 'react-redux';
import styled, { ThemeProvider as LegacyThemeProvider } from 'styled-components';
import { BusinessToggles } from 'businessToggles/BusinessToggles';
import legacyTheme from 'config/theme';
import { ThemeProvider } from '@mui/material';
import { theme } from 'customTheme';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { LocaleProvider } from '../contexts/LocaleContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { PageTitleProvider } from '../contexts/PageTitleContext';
import { Auth0ProviderWithNavigate } from '../auth0/Auth0ProviderWithNavigate';
import { AppProvider } from './AppContext';
import { CountriesProvider } from '../contexts/CountriesContext';
import { App } from './App';

const Snackbar = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    fontSize: '2rem',
    lineHeight: '3rem',
  },
}));

const queryClient = new QueryClient();

export const Enroller = () => (
  <SnackbarProvider maxSnack={3} Components={{ success: Snackbar, error: Snackbar }}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LegacyThemeProvider theme={legacyTheme}>
          <LocaleProvider>
            <BusinessToggles>
              <BrowserRouter>
                <PageTitleProvider>
                  <Auth0ProviderWithNavigate>
                    <AppProvider>
                      <CountriesProvider>
                        <QueryClientProvider client={queryClient}>
                          <App />
                        </QueryClientProvider>
                      </CountriesProvider>
                    </AppProvider>
                  </Auth0ProviderWithNavigate>
                </PageTitleProvider>
              </BrowserRouter>
            </BusinessToggles>
          </LocaleProvider>
        </LegacyThemeProvider>
      </ThemeProvider>
    </Provider>
  </SnackbarProvider>
);
