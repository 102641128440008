import * as types from 'app/constants/ActionTypes';
import { theme } from 'customTheme';

export const schoolInitialState = {
  logo: null,
  signature: {
    preview: null,
  },
  name: 'Enroller',
  color: theme.palette.primary.main,
  status: 'default',
  editStatus: 'default',
  applicationsEditStatus: 'default',
  paymentId: null,
  origins: [],
  applicationsEnrolled: 0,
  avgConversationTime: '',
  enrolledOutOfTotalApplication: '',
  totalApplications: 0,
  totalApplicationsInPipeline: 0,
  applicationsByCountry: [],
  totalLeads: 0,
  leadsByCountry: [],
  isdActions: [],
  nonIsdActions: [],
  hasRequestedStats: false,
  currency_code: 'NZD',
  donutEnquiries: [],
  donutEnrolments: [],
  donutEnrolledStudents: [],
  graphEnquiriesByMonth: [],
  graphEnquiryTypes: [],
  graphEnquiryRoles: [],
};

export const schoolReducer = (state = schoolInitialState, action) => {
  switch (action.type) {
    case types.SCHOOL_RESET:
      return {
        ...schoolInitialState,
        slug: null,
      };
    case types.SCHOOL_SELECT:
      return {
        ...state,
        ...action.school,
      };
    case types.SCHOOL_GET:
      return {
        ...state,
        status: 'loading',
      };
    case types.SCHOOL_GET_RESPONSE:
      if (action.payload.success) {
        return {
          ...state,
          ...action.payload.school,
          status: 'success',
          country: action.payload.school.country && action.payload.school.country.full_name,
          country_id: action.payload.school.country && action.payload.school.country.id,
          logo: action.payload.school.logo.preview,
        };
      }

      return {
        ...state,
      };
    case types.SCHOOL_GET_STATS_RESPONSE:
      if (action.payload.success) {
        return {
          ...state,
          hasRequestedStats: true,
          applicationsEnrolled: action.payload.stats.applications_enrolled,
          avgConversationTime: action.payload.stats.avg_conversation_time,
          enrolledOutOfTotalApplication: action.payload.stats.enrolled_out_of_total_application,
          totalApplications: action.payload.stats.total_applications,
          totalApplicationsInPipeline: action.payload.stats.total_applications_in_progress,
          totalSubmittedApplications: action.payload.stats.total_submitted_applications,
          applicationsByCountry: action.payload.stats.applications_by_country,
          totalLeads: action.payload.stats.total_leads,
          leadsByCountry: action.payload.stats.leads_by_country,
          totalApplyActionsForSchool: action.payload.stats.total_apply_actions_for_school,
          totalApplyActionsForApplicant: action.payload.stats.total_apply_actions_for_applicant,
          totalInterviewActionsForSchool: action.payload.stats.total_interview_actions_for_school,
          totalInterviewActionsForApplicant: action.payload.stats.total_interview_actions_for_applicant,
          totalOfferActionsForSchool: action.payload.stats.total_offer_actions_for_school,
          totalOfferActionsForApplicant: action.payload.stats.total_offer_actions_for_applicant,
          totalVisaActionsForSchool: action.payload.stats.total_visa_actions_for_school,
          totalVisaActionsForApplicant: action.payload.stats.total_visa_actions_for_applicant,
          totalConfirmActionsForSchool: action.payload.stats.total_confirm_actions_for_school,
          totalConfirmActionsForApplicant: action.payload.stats.total_confirm_actions_for_applicant,
          isdActions: action.payload.stats.isd_actions,
          nonIsdActions: action.payload.stats.non_isd_actions,
          donutEnquiries: action.payload.stats.enquiries,
          donutEnrolments: action.payload.stats.enrolments,
          donutEnrolledStudents: action.payload.stats.enrolled_students,
          graphEnrolledStudentsCourses: action.payload.stats.enrolled_students_courses,
          graphEnquiryTypes: action.payload.stats.enquiry_types,
          graphEnquiryReasons: action.payload.stats.enquiry_reasons,
          graphEnquiryRoles: action.payload.stats.enquiry_roles,
          graphEnquiriesByMonth: action.payload.stats.enquiries_by_month,
          graphEnrolmentsStage: action.payload.stats.enrolments_stage,
        };
      }

      return {
        ...state,
        hasRequestedStats: true,
      };
    case types.SCHOOL_EDIT:
      return {
        ...state,
        editStatus: 'loading',
      };
    case types.SCHOOL_EDIT_RESPONSE: {
      let editState = {};

      if (action.payload.success) {
        editState = {
          ...state,
          ...action.payload.school,
          logo: action.payload.school.logo.preview,
          editStatus: 'success',
        };
      } else {
        editState = {
          ...state,
          editStatus: 'default',
        };
      }

      return {
        ...state,
        ...editState,
      };
    }
    case types.APPLICATIONS_EDIT_RESPONSE: {
      let editApplicationState = {};

      if (action.payload.success) {
        editApplicationState = {
          ...state,
          ...action.payload.school,
          logo: action.payload.school.logo.preview,
          applicationsEditStatus: 'success',
        };
      } else {
        editApplicationState = {
          ...state,
          applicationsEditStatus: 'default',
        };
      }

      return {
        ...state,
        ...editApplicationState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
