import { Avatar, Chip, Dialog, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import ProductsAccess from '../../permissions/ProductsAccess';
import { ENROLLER_RECRUIT } from '../../permissions/constants/ProductLevels';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import DescriptionIconOutlined from '@mui/icons-material/DescriptionOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LinkIcon from '@mui/icons-material/Link';
import { ApplicationDocuments } from '../../profile/ApplicationDocuments';
import { ApplicationNotes } from '../../profile/ApplicationNotes';
import { ApplicationParticulars } from '../../profile/ApplicationParticulars';
import { EditApplicant } from '../../profile/editApplicant/EditApplicant';
import { TransferOwnershipDialog } from '../../profile/transferOwnership/TransferOwnershipDialog';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import {
  GROUP_TYPE_INSTITUTION,
  PERMISSION_TRANSFER_OWNERSHIP,
  ROLE_TYPE_APPLICANT,
  ROLE_TYPE_PARENT,
  ROLE_TYPE_INSTITUTION_ADMIN,
  ROLE_TYPE_INSTITUTION_STAFF,
  ROLE_TYPE_SHARED_APPLICANT,
  ROLE_TYPE_AGENT,
} from '../../permissions/constants/Roles';
import { STUDENT_STATUSES_COLORS } from '../../app/constants/StudentStatuses';
import { APPLICATION_STATUS_LABELS, APPLICATION_STATUS_ENROLLED, APPLICATION_STATUS_WITHDRAWN } from '../../app/constants/ApplicationStatuses';
import { MouseEventHandler, useState } from 'react';
import { usePermissions } from '../../contexts/PermissionContext';
import { useMatch, useParams } from 'react-router-dom';
import { SchoolRouteParams } from '../../app/routes/SchoolRoutes';
import { Application } from '../../types/Application';
import { intlFormat, IntlFormatFormatOptions, parseISO } from 'date-fns';
import { Applicant } from '../../types/Applicant';
import { useLocale } from '../../contexts/LocaleContext';

interface ApplicantSummaryProps {
  application?: Application | null;
  applicant: Applicant;
}

export const ApplicantSummary = ({ application = null, applicant }: ApplicantSummaryProps) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const { hasRole, hasGroup, hasPermission } = usePermissions();
  const { localeCode } = useLocale();
  const theme = useTheme();
  const [moreApplicantActionsMenuAnchorEl, setMoreApplicantActionsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDialog, setOpenDialog] = useState<
    'applicationLead' | 'applicationParticulars' | 'applicantDetails' | 'applicantStatus' | 'reassignAgent' | 'applicationDocuments' | 'applicationNotes' | null
  >(null);
  const isApplicantAllEnrolmentsPage = useMatch(`/${schoolSlug}/enrolment-application/applicant/${applicant?.id}/`);

  if (!applicant) return <></>;

  const dateFormat: IntlFormatFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const studentStatus = applicant.student_status?.status;
  const studentStatusColor = studentStatus ? STUDENT_STATUSES_COLORS[studentStatus] : 'inherit';
  const studentStatusContrastText = studentStatus ? theme.palette.getContrastText(studentStatusColor) : 'inherit';
  const creatorFullName = `${applicant.created_by?.first_name} ${applicant.created_by?.last_name}`;

  const dateOfBirth = applicant.date_of_birth ? intlFormat(parseISO(applicant.date_of_birth), dateFormat, { locale: localeCode }) : null;
  const studyStart = application?.start_date ? intlFormat(parseISO(application?.start_date), dateFormat, { locale: localeCode }) : null;
  const studyEnd = application?.end_date ? intlFormat(parseISO(application?.end_date), dateFormat, { locale: localeCode }) : null;
  const studyDates = `${studyStart} - ${studyEnd}`;
  const studyStatusLabel = application?.status ? APPLICATION_STATUS_LABELS[application?.status] : null;
  const studyStatusTooltip = (hasGroup([GROUP_TYPE_INSTITUTION]) && application?.status_update_comment) || '';
  const studyStatusColor =
    application?.status === APPLICATION_STATUS_ENROLLED ? 'success' : application?.status === APPLICATION_STATUS_WITHDRAWN ? 'error' : 'warning';

  const isMoreApplicantActionsMenuOpen = Boolean(moreApplicantActionsMenuAnchorEl);
  const onMoreApplicantActionsClick: MouseEventHandler<HTMLButtonElement> = (event) => setMoreApplicantActionsMenuAnchorEl(event.currentTarget);
  const onMoreApplicantActionsClose: MouseEventHandler<HTMLElement> = () => setMoreApplicantActionsMenuAnchorEl(null);

  const renderViewAllEnrolmentsMenuLink = !hasRole([ROLE_TYPE_SHARED_APPLICANT, ROLE_TYPE_APPLICANT]) && !isApplicantAllEnrolmentsPage;
  const renderEditDetailsMenuLink = applicant.editable;
  const renderEditStatusMenuLink = Boolean(applicant.student_status) && applicant.editable && hasGroup([GROUP_TYPE_INSTITUTION]);
  const renderReassignAgentMenuLink = applicant.created_by && hasGroup([GROUP_TYPE_INSTITUTION]) && hasPermission([PERMISSION_TRANSFER_OWNERSHIP]);
  const renderMoreApplicantActionsMenuButton =
    renderViewAllEnrolmentsMenuLink || renderEditDetailsMenuLink || renderEditStatusMenuLink || renderReassignAgentMenuLink;
  const renderInstitutionMenuLinks = !!application && hasGroup([GROUP_TYPE_INSTITUTION]);
  const renderParticularsMenuLink = renderInstitutionMenuLinks && hasRole([ROLE_TYPE_INSTITUTION_STAFF, ROLE_TYPE_INSTITUTION_ADMIN]);
  const renderLeadsMenuLink = renderInstitutionMenuLinks && !!application?.lead_id;
  const renderCourse = Boolean(application?.course);
  const renderStudyDates = Boolean(studyStart && studyEnd);
  const renderDoB = Boolean(applicant.date_of_birth);
  const renderCreatedByAgentIcon = applicant.created_by?.roles?.includes(ROLE_TYPE_AGENT);
  const renderCreatedByParentIcon = applicant.created_by?.roles?.includes(ROLE_TYPE_PARENT);
  const renderCreatedBy = Boolean(renderCreatedByAgentIcon || renderCreatedByParentIcon);
  const renderCreatedByLabel = `${renderCreatedByAgentIcon ? 'Agent' : 'Parent'}`;
  const renderAgencyName = Boolean(applicant.created_by?.agency?.name);

  const onEditDetailsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicantDetails');
  };
  const onEditStatusMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicantStatus');
  };
  const onReassignAgentMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('reassignAgent');
  };
  const onDocumentsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationDocuments');
  };
  const onNotesMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationNotes');
  };
  const onParticularsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationParticulars');
  };
  const onLeadsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationLead');
  };

  return (
    <Stack direction="column">
      <Stack direction="row" width="100%" spacing={2} alignItems="center">
        <Typography variant="h4" component="h1" color="primary" textTransform="capitalize">
          {applicant.first_name} {applicant.last_name}
        </Typography>
        {studentStatus && (
          <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
            <Chip
              label={studentStatus}
              size="small"
              sx={{
                bgcolor: studentStatusColor,
                color: studentStatusContrastText,
              }}
            />
          </ProductsAccess>
        )}
        {renderMoreApplicantActionsMenuButton && (
          <Tooltip title="More applicant actions">
            <IconButton aria-label="more applicant actions" onClick={onMoreApplicantActionsClick}>
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
        )}
        <Menu anchorEl={moreApplicantActionsMenuAnchorEl} open={isMoreApplicantActionsMenuOpen} onClose={onMoreApplicantActionsClose}>
          {renderViewAllEnrolmentsMenuLink && (
            <MenuItem href={`/${schoolSlug}/enrolment-application/applicant/${applicant.id}`} onClick={onMoreApplicantActionsClose} {...{ component: Link }}>
              <ListItemIcon>
                <VisibilityOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>View all enrolments</ListItemText>
            </MenuItem>
          )}
          {renderEditDetailsMenuLink && (
            <MenuItem onClick={onEditDetailsMenuClick}>
              <ListItemIcon>
                <EditOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit details</ListItemText>
            </MenuItem>
          )}
          {renderEditStatusMenuLink && (
            <ProductsAccess disallow={[ENROLLER_RECRUIT]}>
              <MenuItem onClick={onEditStatusMenuClick}>
                <ListItemIcon>
                  <TrafficOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit status</ListItemText>
              </MenuItem>
            </ProductsAccess>
          )}
          {renderReassignAgentMenuLink && (
            <MenuItem onClick={onReassignAgentMenuClick}>
              <ListItemIcon>
                <SupervisorAccountOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Re-assign Agent</ListItemText>
            </MenuItem>
          )}
          {renderInstitutionMenuLinks && (
            <MenuItem onClick={onDocumentsMenuClick}>
              <ListItemIcon>
                <DescriptionIconOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Documents</ListItemText>
            </MenuItem>
          )}
          {renderInstitutionMenuLinks && (
            <MenuItem onClick={onNotesMenuClick}>
              <ListItemIcon>
                <EditNoteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Notes</ListItemText>
            </MenuItem>
          )}
          {renderParticularsMenuLink && (
            <MenuItem onClick={onParticularsMenuClick}>
              <ListItemIcon>
                <LinkIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Particulars</ListItemText>
            </MenuItem>
          )}
          {renderLeadsMenuLink && (
            <MenuItem onClick={onLeadsMenuClick}>
              <ListItemIcon>
                <EditNoteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Enquiry notes</ListItemText>
            </MenuItem>
          )}
        </Menu>
        <Dialog fullWidth maxWidth="lg" open={openDialog === 'applicationDocuments'} onClose={() => setOpenDialog(null)}>
          <ApplicationDocuments onToggle={() => setOpenDialog(null)} applicationId={application?.id} />
        </Dialog>
        <Dialog fullWidth maxWidth="md" open={openDialog === 'applicationNotes'} onClose={() => setOpenDialog(null)}>
          <ApplicationNotes onToggle={() => setOpenDialog(null)} recipient="applications" recipientId={application?.id} dialogTitle="Application notes" />
        </Dialog>
        <Dialog fullWidth maxWidth="md" open={openDialog === 'applicationLead'} onClose={() => setOpenDialog(null)}>
          <ApplicationNotes onToggle={() => setOpenDialog(null)} recipient="leads" recipientId={application?.lead_id} dialogTitle="Enquiry notes" />
        </Dialog>
        <Dialog fullWidth maxWidth="lg" open={openDialog === 'applicationParticulars'} onClose={() => setOpenDialog(null)}>
          <ApplicationParticulars onToggle={() => setOpenDialog(null)} />
        </Dialog>
        <Dialog fullWidth maxWidth="lg" open={openDialog === 'applicantDetails' || openDialog === 'applicantStatus'} onClose={() => setOpenDialog(null)}>
          <EditApplicant onToggle={() => setOpenDialog(null)} defaultTab={openDialog === 'applicantStatus' ? 1 : 0} />
        </Dialog>
        <Dialog fullWidth maxWidth="lg" open={openDialog === 'reassignAgent'} onClose={() => setOpenDialog(null)}>
          <TransferOwnershipDialog onClose={() => setOpenDialog(null)} />
        </Dialog>
      </Stack>
      <Stack direction="row" columnGap={3} rowGap={0.5} flexWrap="wrap">
        {applicant.nationality && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              src={`/images/flag-icons/flags/4x3/${applicant.nationality.iso_3166_2.toLowerCase()}.svg`}
              variant="rounded"
              sx={{ width: 20, height: 20, fontSize: '14px' }}
            >
              {applicant.nationality.iso_3166_2}
            </Avatar>
            <Typography color="secondary" variant="subtitle2">
              {applicant.nationality.name}
            </Typography>
          </Stack>
        )}
        {renderDoB && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CakeOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="subtitle2">
              {dateOfBirth}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" spacing={0.5} alignItems="center">
          <PersonOutlinedIcon color="secondary" />
          <Typography color="secondary" variant="subtitle2">
            {applicant.gender}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <EmailOutlinedIcon color="secondary" />
          <Typography color="secondary" variant="subtitle2">
            {applicant.email}
          </Typography>
        </Stack>
        {renderCreatedBy && (
          <Tooltip
            arrow
            title={
              <>
                {renderAgencyName && <Typography color="inherit" fontSize="small">{`Agency name: ${applicant.created_by?.agency?.name}`}</Typography>}
                <Typography color="inherit" fontSize="small">{`${renderCreatedByLabel} name: ${creatorFullName}`}</Typography>
                <Typography color="inherit" fontSize="small">{`${renderCreatedByLabel} email: ${applicant.created_by?.email}`}</Typography>
              </>
            }
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              {renderCreatedByAgentIcon && <BusinessOutlinedIcon color="secondary" />}
              {renderCreatedByParentIcon && <PeopleIcon color="secondary" />}
              <Typography color="secondary" variant="subtitle2" textTransform="capitalize">
                {applicant.created_by?.agency?.name || creatorFullName}
              </Typography>
            </Stack>
          </Tooltip>
        )}
        {renderCourse && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <SchoolOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="subtitle2">
              {application!.course}
            </Typography>
            <Tooltip title={studyStatusTooltip} arrow>
              <Chip label={studyStatusLabel} color={studyStatusColor} variant="outlined" size="small" />
            </Tooltip>
          </Stack>
        )}
        {renderStudyDates && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <DateRangeOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="subtitle2">
              {studyDates}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
