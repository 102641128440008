import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

const applyAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/applications{?status,archived,page,per_page,summary}{/id}{/type}{/stage,method,name}{?action}`,
});

/*
/api/schools/{school-slug}/applications
/api/schools/{school-slug}/applications/initialise
/api/schools/{school-slug}/applications?status={All/InProgress/Withdrawn/Enrolled}&archived={true/false}&page=1&per_page=15
/api/schools/{school-slug}/applications/{application}/stages
// Withdraw
PUT /api/schools/{school-slug}/applications/{application}/withdraw

// Apply
GET /api/schools/{school-slug}/applications/{application}/stages/apply
GET|POST|PUT /api/schools/{school-slug}/applications/{application}/stages/apply/applicant-details
GET|POST|PUT /api/schools/{school-slug}/applications/{application}/stages/apply/caregivers
GET|POST|PUT /api/schools/{school-slug}/applications/{application}/stages/apply/accommodation-medical-information
GET|POST|PUT /api/schools/{school-slug}/applications/{application}/stages/apply/student-information
GET|POST|PUT /api/schools/{school-slug}/applications/{application}/stages/apply/uploads
GET|POST|PUT /api/schools/{school-slug}/applications/{application}/stages/apply/review?action=approve
GET|POST|PUT /api/schools/{school-slug}/applications/{application}/stages/apply/review?action=decline

// Interview
/api/schools/{school-slug}/applications/{application}/stages/interview
/api/schools/{school-slug}/applications/{application}/stages/interview/skip
/api/schools/{school-slug}/applications/{application}/stages/interview/set-interview-times
/api/schools/{school-slug}/applications/{application}/stages/interview/confirm-interview-time
/api/schools/{school-slug}/applications/{application}/stages/interview/notes

// Offer
/api/schools/{school-slug}/applications/{application}/stages/offer
/api/schools/{school-slug}/applications/{application}/stages/offer/create
/api/schools/{school-slug}/applications/{application}/stages/offer/review?action=accept
/api/schools/{school-slug}/applications/{application}/stages/offer/review?action=decline
/api/schools/{school-slug}/applications/{application}/stages/offer/payment
/api/schools/{school-slug}/applications/{application}/stages/offer/payment/confirm
/api/schools/{school-slug}/applications/{application}/stages/offer/payment/send-cop-receipt

// Visa
/api/schools/{school-slug}/applications/{application}/stages/visa
/api/schools/{school-slug}/applications/{application}/stages/visa/yet-to-apply
/api/schools/{school-slug}/applications/{application}/stages/visa/in-process
/api/schools/{school-slug}/applications/{application}/stages/visa/valid-visa

// Confirmation
/api/schools/{school-slug}/applications/{application}/stages/confirmation
/api/schools/{school-slug}/applications/{application}/stages/confirmation/complete-application
/api/schools/{school-slug}/applications/{application}/stages/confirmation/confirm

// Leads
/api/schools/{school-slug}/applications/{application}/search-leads

// Flight details
/api/schools/{school-slug}/applications/{application}/flight-details
*/

const api = {
  POST: applyAPI.wrap(defaultRequest, { method: 'POST' }),
  GET: applyAPI.wrap(defaultRequest, { method: 'GET' }),
  PUT: applyAPI.wrap(defaultRequest, { method: 'PUT' }),
};

export default api;
