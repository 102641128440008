import { useLayoutEffect, useState } from 'react';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import Country from 'components/Country/Country';
import { FLAG_LANGUAGE_ASSIST } from 'businessToggles/Flags';
import HasFeature from 'businessToggles/HasFeature';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useLocale } from 'contexts/LocaleContext';
import { useLocation } from 'react-router-dom';

export const LocaleMenu = () => {
  const { label: localeLabel, image: localeImage, setLocaleValues } = useLocale();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  useLayoutEffect(() => setAnchorEl(null), [location.pathname]);

  const handleOpen: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  type SelectLocaleHandler = (label: string, locale: string, image: string) => React.MouseEventHandler<HTMLLIElement>;

  const handleSelectLocale: SelectLocaleHandler = (newLabel, newLocale, newImage) => (event) => {
    event.preventDefault();
    setLocaleValues(newLocale, newLabel, newImage);
    handleClose();
  };

  return (
    <HasFeature name={[FLAG_LANGUAGE_ASSIST]}>
      <Stack direction="row" onClick={handleOpen} sx={{ cursor: 'pointer' }}>
        <Country name={localeLabel} code={localeImage} hideCode>
          <Typography>{localeLabel}</Typography>
        </Country>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Stack>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleSelectLocale('EN', 'en', 'gb')}>
          <Country name="EN" code="gb" hideCode>
            <Typography variant="subtitle2" color="secondary">
              EN
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={handleSelectLocale('CN', 'zh-CN', 'cn')}>
          <Country name="CN" code="cn" hideCode>
            <Typography variant="subtitle2" color="secondary">
              CN
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={handleSelectLocale('JP', 'ja', 'jp')}>
          <Country name="JP" code="jp" hideCode>
            <Typography variant="subtitle2" color="secondary">
              JP
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={handleSelectLocale('KO', 'ko', 'kr')}>
          <Country name="KO" code="kr" hideCode>
            <Typography variant="subtitle2" color="secondary">
              KO
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={handleSelectLocale('TH', 'th', 'th')}>
          <Country name="TH" code="th" hideCode>
            <Typography variant="subtitle2" color="secondary">
              TH
            </Typography>
          </Country>
        </MenuItem>
        <MenuItem onClick={handleSelectLocale('VN', 'vi', 'vn')}>
          <Country name="VN" code="vn" hideCode>
            <Typography variant="subtitle2" color="secondary">
              VN
            </Typography>
          </Country>
        </MenuItem>
      </Menu>
    </HasFeature>
  );
};
