import * as ActionTypes from 'app/constants/ActionTypes';
import * as ErrorActions from 'app/actions/errorActions';
import userAPI from 'app/api/userAPI';
import { getConfig } from '../../utils/config';

/**
 * Set locale for translations
 * @param {String} locale Locale
 * @param {String} label  Label for nav
 * @param {String} img    Flag image name
 */
export function setLocale(locale, label, img) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.USER_SET_LOCALE,
      locale,
      label,
      img,
    });
  };
}

/**
 * Resend Account Verification
 * An API GET request is made, dispatching
 * actions for success/error
 */
export function resendAccountVerification(email) {
  return function (dispatch) {
    const { apiUrl } = getConfig();

    dispatch({
      type: ActionTypes.USER_GET_RESEND_ACCOUNT_VERIFICATION,
    });

    userAPI
      .PUT({
        path: `${apiUrl}/users/resend-verification{?email}`,
        params: {
          email,
        },
      })
      .then((response) => {
        if (response.status.code === 200) {
          dispatch({
            type: ActionTypes.USER_GET_RESEND_ACCOUNT_VERIFICATION_RESPONSE,
            payload: {
              success: true,
              message: response.entity.message,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.USER_GET_RESEND_ACCOUNT_VERIFICATION_RESPONSE,
          payload: {
            success: false,
          },
        });

        ErrorActions.show(error)(dispatch);
      });
  };
}
