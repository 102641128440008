import { Branding } from '../branding.interface';
import { EnrollerFooter } from './EnrollerFooter';
import { EnrollerHeader } from './EnrollerHeader';

const primaryMain = '#3EAD47';

export const enrollerBranding: Branding = {
  header: <EnrollerHeader />,
  footer: <EnrollerFooter />,
  fontFamily: 'Inter, sans-serif',
  paletteOptions: {
    primary: {
      main: primaryMain,
      light: '#C5E6C8',
      dark: '#039855',
      contrastText: '#ffffff',
    },
    menuColor: 'inherit',
  },
};
