import { useApplication } from '../../contexts/ApplicationContext';
import { WorkflowContent } from './WorkflowContent';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import getCurrentStage from '../../workflow/utils/getCurrentStage';
import Loader from '../Loader';
import { ApplicantRouteParams } from './Workflow';
import { getApplicationUrlForCurrentParty } from './utils/getApplicationUrlForCurrentParty';

export interface WorkflowStepRouteParams extends ApplicantRouteParams {
  step: string;
  stage: string;
}

export const WorkflowApplicationLayout = () => {
  const { slug: schoolSlug, applicant: applicantId } = useParams() as WorkflowStepRouteParams;
  const {
    state: { application },
  } = useApplication();

  if (!application) {
    return <Loader fullPage />;
  }

  const renderRedirectToStep = () => {
    const stage = getCurrentStage(application.workflow, application.current_stage_id);
    const url = getApplicationUrlForCurrentParty(application, stage.slug, schoolSlug, applicantId);

    if (url) {
      return <Navigate to={url} />;
    }
    // TODO: notification to say there is no accessible step to navigate to
    return <Navigate to="../../" />;
  };

  return (
    <Routes>
      <Route path="/*" element={renderRedirectToStep()} />
      <Route path="stages/:stage/steps/:step" element={<WorkflowContent />} />
    </Routes>
  );
};
