import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { WorkflowManagementProvider } from 'views/Management/WorkflowManagementContext';

const Workflows = React.lazy(() => import(/* webpackChunkName: 'management-workflows' */ 'views/Management/Workflows'));

const Workflow = React.lazy(() => import(/* webpackChunkName: 'management-workflows' */ 'views/Management/Workflow'));

export const ManagementRoutes = () => {
  return (
    <Routes>
      <Route path="workflows" element={<Workflows />} />
      <Route
        path="workflows/:workflowId/*"
        element={
          <WorkflowManagementProvider>
            <Routes>
              <Route path="/" element={<Workflow />} />
            </Routes>
          </WorkflowManagementProvider>
        }
      />
    </Routes>
  );
};
