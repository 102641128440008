import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { intlFormat, parseISO } from 'date-fns';
import theme from 'config/theme';
import { isExpired } from 'utils/isExpired';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, IconButton, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { resendLink, revokeAccess } from 'app/actions/shareActions';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Controller, useForm } from 'react-hook-form';
import validators from 'utils/validators';
import { usePost } from 'hooks/usePost';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { getErrorMessage } from 'utils/errors';
import { useLocale } from '../contexts/LocaleContext';

const LOADING = 'loading';
const SUCCESS = 'success';

interface ShareApplicationFormValues {
  email: string;
  note: string;
}

export const ShareApplicationDialog = ({ active, share, applicationId, onShareApplication, onToggle }) => {
  const dispatch = useAppDispatch();
  const resendStatus = useAppSelector((state) => state.shares.resendShareStatus);
  const revokeStatus = useAppSelector((state) => state.shares.revokeShareStatus);
  const shareStatus = useAppSelector((state) => state.shares.createShareStatus);
  const [email, setEmail] = useState('');
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [isSubmitting, shareApplication] = usePost<ResponseEnvelope<ShareApplicationFormValues>>(`/schools/${schoolSlug}/applications/${applicationId}/share`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();
  const [shareSuccessful, setShareSuccessful] = useState(false);
  const { localeCode } = useLocale();

  const handleRevokeAccess = (revokeShare) => {
    dispatch(revokeAccess(revokeShare, onShareApplication, applicationId, schoolSlug));
  };

  const handleResendLink = (resendShare) => {
    dispatch(resendLink(resendShare, onShareApplication, schoolSlug));
  };

  const handleClose = () => {
    setShareSuccessful(false);
    reset();
    onToggle();
  };

  const isLoading = (status) => {
    return status === LOADING;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ShareApplicationFormValues>({
    defaultValues: { email: '', note: '' },
  });

  const onSubmit = handleSubmit(({ email: formEmail, note }: ShareApplicationFormValues) => {
    setEmail(formEmail);
    const formValues = {
      email: formEmail,
      note: note ?? '',
    };
    shareApplication(formValues)
      .then((res) => {
        showSuccessMessage(res.message);
        setShareSuccessful(true);
        onShareApplication();
      })
      .catch((error) => showErrorMessage(getErrorMessage(error)));
  });

  const renderForm = () => {
    if (shareSuccessful) {
      return (
        <>
          <Typography variant="body1">
            Thank you, <strong>{email}</strong> will now receive an email and an automatically generated password to have access to input information. You will
            be notified when they have submitted the information and will then be able to review and edit as required before submitting the application to the
            school.
          </Typography>
          <Typography variant="body1">
            Please note, this access will expire after 10 days. You will be able to share the application again if more time is needed.
          </Typography>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </>
      );
    }

    if (share) {
      return <></>;
    }

    return (
      <>
        <Typography variant="body1" mb={1}>
          Please enter the email address of the person you wish to grant access to fill in the application information, an email with a link and password will
          then be sent with instructions on how to fill out the form.
        </Typography>
        <form onSubmit={onSubmit}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email address is required',
              validate: (value) => (validators.email(value) ? true : 'Email address is invalid'),
            }}
            render={({ field: { value, onChange } }) => (
              <>
                <FormControl fullWidth error={!!errors.email}>
                  <FormLabel id="emailLabel" required>
                    Email address
                  </FormLabel>
                  <TextField size="small" aria-labelledby="emailLabel" value={value} onChange={onChange} error={!!errors.email} data-cy="shared-user-email" />
                </FormControl>
                <Typography color="error">{errors.email ? errors.email.message : <br />}</Typography>
              </>
            )}
          />
          <Controller
            name="note"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <FormControl fullWidth>
                  <FormLabel id="noteLabel">Add a note (optional)</FormLabel>
                  <TextField multiline rows={3} size="small" aria-labelledby="noteLabel" value={value} onChange={onChange} />
                </FormControl>
              </>
            )}
          />
          <DialogActions>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting} data-cy="share-button">
              Share
            </LoadingButton>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </form>
      </>
    );
  };

  const renderShares = () => {
    if (!share || shareStatus === SUCCESS) {
      return <></>;
    }

    const expiryDate = share.expires_at;

    return (
      <Stack mb={2}>
        <Typography variant="h6" mb={2}>
          You have granted access to fill in this application to:
        </Typography>
        <Stack
          gap={2}
          alignItems="center"
          direction="row"
          sx={{
            p: 2,
            mb: 2,
            border: '1px solid #e7e7e7',
            borderLeft: `3px solid ${isExpired(expiryDate) ? theme.overdue : theme.primary}`,
            height: '50px',
          }}
        >
          <Typography fontWeight="bold" variant="body1">
            {share.email}
          </Typography>
          <Typography variant="body1">
            {isExpired(expiryDate) ? 'Access expired' : 'Access expires'} on {intlFormat(parseISO(expiryDate), { locale: localeCode })}
          </Typography>
          {isLoading(resendStatus) ? (
            <Typography variant="body1">Resend link</Typography>
          ) : (
            <Button onClick={() => handleResendLink(share)}>Resend link</Button>
          )}
        </Stack>
        <Typography variant="body1">
          Only one email address can be given access at a time. If you would like to share this application with someone else please click on Revoke Access
          button and re-share the form to a new email address.
        </Typography>
        <DialogActions>
          <LoadingButton variant="contained" onClick={() => handleRevokeAccess(share)} loading={isLoading(revokeStatus)} disabled={isLoading(revokeStatus)}>
            Revoke access
          </LoadingButton>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Stack>
    );
  };

  return (
    <Dialog open={active} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Share Application
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {renderShares()}
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};
