import { ButtonBase, FormControl, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { AddressAutocomplete } from 'components/AddressAutocomplete/AddressAutocomplete';
import { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';

interface AddressValue {
  address: string;
  city: string;
  country: string;
  postalCode: string;
}

interface PlacesAutocompleteFieldProps {
  label: string;
  disabled: boolean;
  value: AddressValue;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const PlacesAutocompleteField = ({ label, disabled, value, onChange, error, name, readOnly }: PlacesAutocompleteFieldProps) => {
  const [showAddressFields, setShowAddressFields] = useState(false);
  const toggleLinkText = showAddressFields ? 'Search for address instead' : "Can't find your address? Enter it manually";

  const handleInputChange = (key, val) => {
    const newValue = value || {};
    newValue[key] = val;
    onChange(newValue);
  };

  const addressInputs = [
    { name: 'address', label: 'Address' },
    { name: 'city', label: 'Current City' },
    { name: 'country', label: 'Current Country' },
    { name: 'postalCode', label: 'Current Postal Code' },
  ];

  useEffect(() => {
    // If the user has added their address, we need to show the address fields by default
    if (value || readOnly) {
      setShowAddressFields(true);
    }
  }, [value, readOnly]);

  return (
    <>
      <Stack aria-labelledby={`${name}-label`} direction="row" alignItems="center" gap={3}>
        <FormControl fullWidth size="small" data-cy-field-type="placesautocomplete" sx={{ flexBasis: '33%' }}>
          <FormLabel id={`${name}-label`}>{label}</FormLabel>
          <AddressAutocomplete
            onChange={({ addressLine1: address, city, country, postCode: postalCode }) => {
              onChange({ address, city, country, postalCode });
            }}
            disabled={disabled || readOnly}
          />
        </FormControl>
        <ButtonBase sx={{ color: (theme) => theme.palette.primary.main, mt: 2 }} onClick={() => setShowAddressFields(!showAddressFields)}>
          {toggleLinkText}
        </ButtonBase>
      </Stack>
      {showAddressFields && (
        <Stack flexWrap="wrap" direction="row" gap={3} justifyContent="space-between" mt={2}>
          {addressInputs.map(({ name: inputName, label: inputLabel }) => (
            <FormControl key={inputName} fullWidth size="small" sx={{ flexBasis: (theme) => `calc(50% - ${theme.spacing(2)})` }}>
              <FormLabel id={`${name}-${inputName}-label`}>{inputLabel}</FormLabel>
              <TextField
                size="small"
                aria-labelledby={`${name}-${inputName}-label`}
                value={value[inputName]}
                onChange={(event) => handleInputChange(inputName, event.target.value)}
                inputProps={{ readOnly }}
                disabled={disabled}
              />
            </FormControl>
          ))}
        </Stack>
      )}
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default PlacesAutocompleteField;
