import { format, parse } from 'date-fns';
import { useApplicant } from 'contexts/ApplicantContext';
import { Controller, useForm } from 'react-hook-form';
import { Button, DialogActions, FormControl, FormLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Applicant } from 'types/Applicant';
import Loader from 'components/Loader';
import { DatePicker } from '@mui/x-date-pickers';
import validators from 'utils/validators';
import { DATE_DASH_FORMAT } from '../../app/constants/DateFormats';
import { useCountries } from '../../contexts/CountriesContext';
import { useMemo } from 'react';

interface EditDetailsForm extends Omit<Applicant, 'date_of_birth'> {
  date_of_birth: Date;
}

interface EditDetailsProps {
  onToggle: () => void;
}

export const EditDetails = ({ onToggle }: EditDetailsProps) => {
  const {
    state: { applicant, status },
    updateApplicant,
  } = useApplicant();
  const countries = useCountries();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<EditDetailsForm>({
    defaultValues: { ...applicant!, date_of_birth: applicant?.date_of_birth ? parse(applicant?.date_of_birth!, DATE_DASH_FORMAT, new Date()) : undefined },
  });

  const onSubmit = handleSubmit((data) => {
    return updateApplicant({ ...data, date_of_birth: format(data.date_of_birth, DATE_DASH_FORMAT) }, true);
  });

  const countryOptions = useMemo(
    () =>
      countries.map((country) => (
        <MenuItem key={country.id} value={country.id}>
          {country.name}
        </MenuItem>
      )),
    [countries],
  );

  if (!applicant) {
    return <Loader />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={0.5}>
        <Typography>Please enter the details of the applying student.</Typography>
        <Controller
          name="first_name"
          control={control}
          rules={{
            required: 'First name is required',
            maxLength: {
              value: 200,
              message: 'First name is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.first_name}>
                <FormLabel id="firstNameLabel">First name</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="firstNameLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.first_name)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.first_name ? errors.first_name.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="middle_name"
          control={control}
          rules={{
            maxLength: {
              value: 200,
              message: 'Middle name is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth error={!!errors.middle_name}>
                <FormLabel id="middleNameLabel">Middle name</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="middleNameLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.middle_name)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.middle_name ? errors.middle_name.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="last_name"
          control={control}
          rules={{
            required: 'Last name is required',
            maxLength: {
              value: 200,
              message: 'Last name is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.last_name}>
                <FormLabel id="lastNameLabel">Family name</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="lastNameLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.last_name)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.last_name ? errors.last_name.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="preferred_name"
          control={control}
          rules={{
            required: 'Preferred name is required',
            maxLength: {
              value: 200,
              message: 'Preferred name is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.preferred_name}>
                <FormLabel id="preferredNameLabel">Preferred name / known as</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="preferredNameLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.preferred_name)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.preferred_name ? errors.preferred_name.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="gender"
          control={control}
          rules={{
            required: 'Gender is required',
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.gender}>
                <FormLabel id="genderLabel">Gender</FormLabel>
                <Select
                  size="small"
                  variant="outlined"
                  aria-labelledby="genderLabel"
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.gender)}
                  disabled={status === 'pending'}
                >
                  <MenuItem key="0" value="Male">
                    Male
                  </MenuItem>
                  <MenuItem key="1" value="Female">
                    Female
                  </MenuItem>
                  <MenuItem key="2" value="Diverse">
                    Diverse
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography color="error">{errors.gender ? errors.gender.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            maxLength: {
              value: 200,
              message: 'Email is too long',
            },
            validate: (value) => (validators.email(value) ? true : 'Email address is invalid'),
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.email}>
                <FormLabel id="emailLabel">Student email address</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="emailLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.email)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.email ? errors.email.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="date_of_birth"
          control={control}
          rules={{
            required: 'Date of birth is required',
            validate: validators.dateOfBirth,
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.date_of_birth}>
                <FormLabel id="dobLabel">Date of birth</FormLabel>
                <DatePicker
                  aria-labelledby="dobLabel"
                  disableFuture
                  value={value}
                  slotProps={{ textField: { size: 'small', error: !!errors.date_of_birth } }}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.date_of_birth ? errors.date_of_birth.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="nationality_id"
          control={control}
          rules={{
            required: 'Nationality is required',
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.nationality_id}>
                <FormLabel id="nationalityLabel">Nationality (as shown on passport)</FormLabel>
                <Select
                  size="small"
                  variant="outlined"
                  aria-labelledby="nationalityLabel"
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.nationality_id)}
                  disabled={status === 'pending'}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: '30vh' },
                    },
                  }}
                >
                  {countryOptions}
                </Select>
              </FormControl>
              <Typography color="error">{errors.nationality_id ? errors.nationality_id.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="address_line_1"
          control={control}
          rules={{
            required: 'Address (Line 1) is required',
            maxLength: {
              value: 200,
              message: 'Address (Line 1) is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.address_line_1}>
                <FormLabel id="addressLine1Label">Address (Line 1)</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="addressLine1Label"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.address_line_1)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.address_line_1 ? errors.address_line_1.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="address_line_2"
          control={control}
          rules={{
            maxLength: {
              value: 200,
              message: 'Address (Line 2) is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth error={!!errors.address_line_2}>
                <FormLabel id="addressLine2Label">Address (Line 2)</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="addressLine2Label"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.address_line_2)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.address_line_2 ? errors.address_line_2.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="city"
          control={control}
          rules={{
            required: 'City / town is required',
            maxLength: {
              value: 200,
              message: 'City / town is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.city}>
                <FormLabel id="cityLabel">City / town</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="cityLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.city)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.city ? errors.city.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="state"
          control={control}
          rules={{
            required: 'State / province / region is required',
            maxLength: {
              value: 200,
              message: 'State / province / region is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.state}>
                <FormLabel id="stateLabel">State / province / region</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="stateLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.state)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.state ? errors.state.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="postcode"
          control={control}
          rules={{
            required: 'Postcode / ZIP is required',
            maxLength: {
              value: 20,
              message: 'Postcode / ZIP is too long',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.postcode}>
                <FormLabel id="postcodeLabel">Postcode / ZIP</FormLabel>
                <TextField
                  size="small"
                  aria-labelledby="postcodeLabel"
                  value={value || ''}
                  onChange={onChange}
                  error={Boolean(errors.postcode)}
                  disabled={status === 'pending'}
                />
              </FormControl>
              <Typography color="error">{errors.postcode ? errors.postcode.message : <br />}</Typography>
            </>
          )}
        />
        <Controller
          name="country_id"
          control={control}
          rules={{
            required: 'Country is required',
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <FormControl fullWidth required error={!!errors.country_id}>
                <FormLabel id="countryLabel">Country</FormLabel>
                <Select
                  size="small"
                  variant="outlined"
                  aria-labelledby="countryLabel"
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.country_id)}
                  disabled={status === 'pending'}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: '30vh' },
                    },
                  }}
                >
                  {countryOptions}
                </Select>
              </FormControl>
              <Typography color="error">{errors.country_id ? errors.country_id.message : <br />}</Typography>
            </>
          )}
        />
      </Stack>
      <DialogActions>
        <Button size="small" variant="contained" disabled={status === 'pending' || !isDirty} onClick={onSubmit}>
          Save
        </Button>
        <Button size="small" variant="outlined" disabled={status === 'pending'} onClick={onToggle} color="warning">
          Cancel
        </Button>
      </DialogActions>
    </form>
  );
};
