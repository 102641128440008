import { Box, Stack } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { branding } from 'branding';
import { createContext, ReactNode, useContext, useLayoutEffect, useState } from 'react';

const AppInnerLayout = () => {
  const { SubHeader } = useSubHeader();

  return (
    <Stack direction="column" height="100vh" width="100vw">
      <Box position="sticky" top="0">
        {branding.header}
        {!!SubHeader && (
          <Box pt={3} width="100vw" bgcolor="background.paper" border="1px solid" borderColor="divider">
            {SubHeader}
          </Box>
        )}
      </Box>
      <Stack flexGrow="1" width="100%" style={{ overflowY: 'scroll' }}>
        <Box flex={1}>
          <Outlet />
        </Box>
        {branding.footer}
      </Stack>
    </Stack>
  );
};

interface HeaderContextData {
  SubHeader?: ReactNode;
  setSubHeader: (header: ReactNode) => void;
}

const HeaderContext = createContext<HeaderContextData | undefined>(undefined);

export const AppLayout = () => {
  const [SubHeader, setSubHeader] = useState<HeaderContextData['SubHeader'] | undefined>();
  const location = useLocation();

  useLayoutEffect(() => setSubHeader(undefined), [location.pathname]);

  return (
    <HeaderContext.Provider value={{ SubHeader, setSubHeader }}>
      <AppInnerLayout />
    </HeaderContext.Provider>
  );
};

export const useSubHeader = () => {
  const context = useContext(HeaderContext);

  if (context === undefined) {
    throw new Error('useSubHeader must be used within a AppLayout');
  }

  return context;
};
