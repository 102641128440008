import * as types from 'app/constants/ActionTypes';
import { FEE_STRUCTURE_ITEMS } from 'app/constants/env';
import { cloneDeep } from 'lodash';

const defaultItems = Array(...Array(FEE_STRUCTURE_ITEMS)).map((_, index) => {
  return {
    id: `new-${index}`,
    description: '',
    quantity: '',
    amount: '',
  };
});

export const defaultTemplate = {
  name: '',
  items: defaultItems,
};

export const financialInitialState = {
  invoiceStatus: 'default',
  invoiceTemplates: [],
  currentInvoiceTemplate: cloneDeep(defaultTemplate),
};

export const financialReducer = (state = financialInitialState, action) => {
  switch (action.type) {
    case types.INVOICE_UPDATE_ROW:
      if (action.section === 'invoices') {
        const items = state.currentInvoiceTemplate.items.slice();

        items[action.index][action.field] = action.value;

        return {
          ...state,
          currentInvoiceTemplate: {
            ...state.currentInvoiceTemplate,
            items,
          },
        };
      }

      return {
        ...state,
      };
    case types.APPLICATIONS_ADD_INVOICE_TEMPLATE: {
      const newInvoiceTemplates: any[] = state.invoiceTemplates.slice();

      newInvoiceTemplates.push({
        name: 'Invoice template name',
        total: 0,
        items: [
          {
            description: 'Tuition',
          },
        ],
      });

      return {
        ...state,
        invoiceTemplates: newInvoiceTemplates,
      };
    }
    case types.APPLICATIONS_UPDATE_INVOICE_TEMPLATE_NAME:
      return {
        ...state,
        currentInvoiceTemplate: {
          ...state.currentInvoiceTemplate,
          name: action.name,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
