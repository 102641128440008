import { createContext, useContext, useState, useEffect } from 'react';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { Workflow } from 'components/Workflow/types/Workflow';
import { useParams } from 'react-router-dom';
import { getErrorMessage } from 'utils/errors';
import { useNotificationMessages } from 'hooks/useNotificationMessages';

const WorkflowManagementContext = createContext<Workflow | undefined>(undefined);

export const WorkflowManagementProvider = ({ children }) => {
  const [workflow, setWorkflow] = useState<Workflow | undefined>(undefined);
  const { workflowId } = useParams();
  const getWorkflow = useStatelessGet<ResponseEnvelope<Workflow>>(`/admin/workflows/${workflowId}`);
  const { showErrorMessage } = useNotificationMessages();

  useEffect(() => {
    getWorkflow()
      .then((response) => {
        setWorkflow(response.data);
      })
      .catch((error) => showErrorMessage(getErrorMessage(error)));
  }, [getWorkflow, showErrorMessage]);

  return <WorkflowManagementContext.Provider value={workflow}>{children}</WorkflowManagementContext.Provider>;
};

export const useWorkflow = () => {
  const context = useContext(WorkflowManagementContext);
  if (context === null) {
    throw new Error('useWorkflow must be used within a WorkflowManagementProvider');
  }

  return context;
};
