import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiFormBuilderStep } from '../../Workflow/WorkflowFormBuilder';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useApplication } from 'contexts/ApplicationContext';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { FormField } from '../FormField';
import { Control } from 'react-hook-form';
import { FormBuilderField } from '../types/FormBuilderField';
import { convertApiFieldsToBuilderFields } from '../utils/convertApiFieldsToBuilderFields';
import { getValuesFromFormData } from '../FormBuilder';
import Loader from '../../Loader';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useWorkflow } from 'contexts/WorkflowContext';

interface StepSummaryFieldProps {
  stepIds: string[];
  control: Control;
}
interface StepItemProps {
  stepName: string;
  sourceUrl: string;
  control: Control;
}

export const StepItem = ({ stepName, sourceUrl, control }: StepItemProps) => {
  const [fields, setFields] = useState<FormBuilderField[]>([]);
  const [values, setValues] = useState<Record<string, any>>(new Map());
  const getFields = useStatelessGet<ResponseEnvelope<ApiFormBuilderStep>>(sourceUrl);
  const {
    state: { application },
  } = useApplication();

  useEffect(() => {
    getFields().then((response) => {
      if (response.data.fields) {
        const fieldsResponse = convertApiFieldsToBuilderFields(response.data.fields);
        setValues(getValuesFromFormData(fieldsResponse, application?.custom_questions || {}, application?.custom_question_answers || {}));
        setFields(fieldsResponse);
      }
    });
  }, [application?.custom_question_answers, application?.custom_questions, getFields]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{stepName}</AccordionSummary>
      <AccordionDetails>
        {fields.map((field) => (
          <FormField value={values[field.slug]} key={field.id} fields={fields} field={field} control={control} disabled />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const StepSummaryField = ({ stepIds, control }: StepSummaryFieldProps) => {
  const {
    state: { application },
  } = useApplication();
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;

  const {
    state: { workflow },
  } = useWorkflow();

  const stepNames = useMemo(() => {
    const map = new Map<string, string>();
    workflow?.stages.forEach((stage) => stage.steps.forEach((step) => stepIds.includes(step.id) && map.set(step.id, step.name)));
    return map;
  }, [stepIds, workflow]);

  if (!application) return <Loader />;

  return (
    <Stack data-cy-field-type="step-summary" gap={1}>
      {stepIds.map((stepId, index) => {
        const sourceUrl = `/schools/${schoolSlug}/applications/${application.id}/stages/${application.current_stage_id}/steps/${stepId}`;

        return (
          <Box data-cy={`step-${index}`} key={stepId}>
            <StepItem stepName={stepNames.get(stepId) || 'Step'} sourceUrl={sourceUrl} control={control} />
          </Box>
        );
      })}
    </Stack>
  );
};

export default StepSummaryField;
