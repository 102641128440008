import { Outlet, Route, Routes } from 'react-router-dom';
import { SchoolWorkflowRouteParams } from 'app/routes/SchoolWorkflowRoutes';
import { ApplicantEnrolmentsLayout } from './ApplicantEnrolmentsLayout';
import { WorkflowApplicationLayout } from './WorkflowApplicationLayout';
import { ApplicationProvider } from '../../contexts/ApplicationContext';
import { PrivateRoute } from '../../app/PrivateRoute';
import { ApplicantProvider } from '../../contexts/ApplicantContext';

export interface ApplicantRouteParams extends SchoolWorkflowRouteParams {
  application: string;
}

export const Workflow = () => {
  return (
    <Routes>
      {/* TODO: This route is not being used at the moment, check if it still needs to exist. See https://bitbucket.org/enrollernz/enrolments-frontend/pull-requests/144 */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <ApplicantProvider>
              <ApplicantEnrolmentsLayout />
            </ApplicantProvider>
          </PrivateRoute>
        }
      />

      <Route
        element={
          <PrivateRoute>
            <ApplicantProvider>
              <ApplicationProvider>
                <Outlet />
              </ApplicationProvider>
            </ApplicantProvider>
          </PrivateRoute>
        }
      >
        <Route path="application/:application/*" element={<WorkflowApplicationLayout />} />
      </Route>
    </Routes>
  );
};
