import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

const invitationsAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/invitations/{?active}`,
});

const api = {
  byToken: {
    GET: client.wrap(template).wrap(defaultRequest, {
      path: `${apiUrl}/invitations/{token}`,
      method: 'GET',
    }),
  },

  GET: invitationsAPI.wrap(defaultRequest, { method: 'GET' }),

  POST: invitationsAPI.wrap(defaultRequest, { method: 'POST' }),

  DELETE: invitationsAPI.wrap(defaultRequest, {
    path: `${apiUrl}/schools/{slug}/invitations/{invitation}/expire`,
    method: 'DELETE',
  }),
};

export default api;
