import { differenceInDays, formatDistance, parseISO } from 'date-fns';

export const getReminderStatus = (entity): { color: 'secondary' | 'error' | 'warning'; label: string; status: string | null } => {
  let label = '-';
  let status = null;
  let color: 'secondary' | 'error' | 'warning' = 'secondary';

  if (!entity) {
    return {
      label,
      status,
      color,
    };
  }

  if (entity.status && entity.status.toLowerCase() === 'new') {
    return {
      label,
      status: entity.status,
      color: 'error',
    };
  }

  if (entity.reminder && entity.reminder !== '-') {
    const diff = differenceInDays(new Date(), parseISO(entity.reminder));

    if (diff > 0) {
      color = 'error';
      status = entity.status || 'overdue';
      label = formatDistance(new Date(), parseISO(entity.reminder));
      label = label === 'a day' ? '1 day' : label;
    } else if (diff === 0) {
      color = 'warning';
      status = entity.status || 'due';
      label = 'today';
    } else if (diff === -1) {
      label = 'tomorrow';
    }
  }

  return {
    label,
    status,
    color,
  };
};
