import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

const agentsAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/users/{?type}{&active}{?action}{id}`,
});

const api = {
  // GET|HEAD  | api/schools/{school}/agents/{lead}       | schools.agents.show
  // GET|HEAD  | api/schools/{school}/agents              | schools.agents.index
  GET: agentsAPI.wrap(defaultRequest, { method: 'GET' }),

  // POST      | api/schools/{school}/agents              | schools.agents.store
  POST: agentsAPI.wrap(defaultRequest, { method: 'POST' }),

  // PUT|PATCH | api/schools/{school}/agents/{agent}       | schools.agents.update
  PUT: agentsAPI.wrap(defaultRequest, { method: 'PUT' }),

  DELETE: agentsAPI.wrap(defaultRequest, { method: 'DELETE' }),
};

export default api;
