import { useCallback, useEffect, useRef } from 'react';
import anime from 'animejs';
import classnames from 'classnames';
import { T } from 'components/Translation/Translation';
import { ReactComponent as ColumnStepsIcon } from 'components/Tag/img/column-tag-bg.svg';
import 'components/Tag/tagContainer.css';
import 'components/Tag/tag.css';
import { Box, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';

interface TagProps {
  compact?: boolean;
  fill?: number;
  link?: string;
  title: string;
  total?: number;
  translationKey: string;
  withTotals?: boolean;
  counter?: number;
  hideTranslation?: boolean;
}

export const Tag = ({
  compact = false,
  fill = 0,
  link,
  title,
  total = 100,
  translationKey,
  withTotals = false,
  counter = 0,
  hideTranslation = false,
}: TagProps) => {
  const elRef = useRef<HTMLDivElement | HTMLAnchorElement | null>(null);
  const theme = useTheme();

  const animateIndicator = useCallback(
    (delay, duration) => {
      const createFillGradient = (svg) => {
        let progress;
        let difference;
        const progressColor = theme.palette.primary.main;
        let lineFill;
        let progressFill;
        const id = 'id-' + Math.floor(Math.random() * (100 - 1) + 1);
        const ns = svg.namespaceURI;
        const defs = svg.querySelector('defs');
        const grad = document.createElementNS(ns, 'linearGradient');

        grad.setAttribute('id', id);
        const progressBeginStop = document.createElementNS(ns, 'stop');
        const progressStop = document.createElementNS(ns, 'stop');
        // Let's fill the remaining space with the default (gray) colour
        const fillStop = document.createElementNS(ns, 'stop');

        if (fill > 0 && fill <= total) {
          progress = fill > 0 ? fill / total : fill;
          difference = 0;
          lineFill = { offset: difference, stopColor: '#b2b2b2' };
          progressFill = {
            offset: (fill / total).toString(),
            stopColor: progressColor,
          };

          if (fill === total) {
            // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
            progressBeginStop.setAttribute('offset', 0);
            progressBeginStop.setAttribute('stop-color', progressColor);
            grad.appendChild(progressBeginStop);
          }

          // We set the first part of the line (the accent colour/progress so far)
          // The beginning of the gradient (or first stop)
          const gradAnim = document.createElementNS(ns, 'animate');
          gradAnim.setAttribute('begin', '1.1s');
          gradAnim.setAttribute('attributeName', 'offset');
          gradAnim.setAttribute('values', '0;' + progress + ';' + progress);
          gradAnim.setAttribute('dur', '2.5s');
          gradAnim.setAttribute('repeatCount', '1');

          if (fill <= total) {
            // The end of the gradient
            progressStop.setAttribute('offset', progress);
          }

          progressStop.appendChild(gradAnim);

          fillStop.setAttribute('offset', lineFill.offset);
          fillStop.setAttribute('stop-color', lineFill.stopColor);
        } else {
          lineFill = { offset: '0', stopColor: '#b2b2b2' };
          progressFill = { offset: '10', stopColor: '#b2b2b2' };

          fillStop.setAttribute('offset', lineFill.offset);
          fillStop.setAttribute('stop-color', lineFill.stopColor);
        }

        progressStop.setAttribute('stop-color', progressFill.stopColor);

        grad.appendChild(progressStop);
        grad.appendChild(fillStop);

        defs.appendChild(grad);

        return id;
      };

      if (elRef.current) {
        const indicator = elRef.current.querySelector('svg')!;
        const indicatorPath = indicator.querySelector('path')!;
        const gradId = createFillGradient(indicator);
        indicatorPath.setAttribute('stroke', 'url(#' + gradId + ')');

        window.requestAnimationFrame(() => {
          anime({
            targets: '.stepcounter__line',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInQuad',
            opacity: 1,
            duration,
            delay,
            loop: false,
          });
        });
      }
    },
    [fill, theme.palette.primary.main, total],
  );

  useEffect(() => {
    animateIndicator(200, 450);
  }, [animateIndicator, compact, fill]);

  const classes = classnames({
    tag: true,
    'tag--compact': compact,
    'tag--link': link,
  });

  const tag = (
    <>
      <Stack width="90%" direction="row">
        <Typography variant="h5" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {title}
        </Typography>
        {withTotals && <Typography variant="h5" ml={1}>{`(${counter})`}</Typography>}
      </Stack>
      <ColumnStepsIcon className="stepcounter" />
      {!hideTranslation && <T className="tag__translation" name={translationKey} />}
    </>
  );

  return (
    <Tooltip
      title={title}
      slotProps={{
        popper: {
          placement: 'bottom-start',
        },
      }}
    >
      {link ? (
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        <Link component="a" href={link} className={classes} ref={elRef}>
          {tag}
        </Link>
      ) : (
        <Box className={classes} ref={elRef}>
          {tag}
        </Box>
      )}
    </Tooltip>
  );
};
