import spacetime from 'spacetime';

export const convertTZ = (date: string | Date, fromTz = '', toTz = ''): Date => {
  if (!date) return new Date();

  const fromDate = spacetime(date, fromTz || spacetime.now().timezone().name);
  const toDate = fromDate.goto(toTz || spacetime.now().timezone().name);

  return new Date(toDate.year(), toDate.month(), toDate.date(), toDate.hour(), toDate.minute());
};
