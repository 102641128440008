import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

const userAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/auth/login?school={slug}`,
});

const api = {
  POST: userAPI.wrap(defaultRequest, { method: 'POST' }),
  PUT: userAPI.wrap(defaultRequest, { method: 'PUT' }),
};

export default api;
