import { format, parseISO } from 'date-fns';
import { DATE_DASH_FORMAT } from 'app/constants/DateFormats';
import { convertRFCtoISO } from 'utils/date';

export function yearLevel(application) {
  const details = application.application_detail && application.application_detail.applicant_details;
  const offer = application.offer;

  if (offer && offer.create_offer && offer.create_offer.course_of_study) {
    return offer.create_offer.course_of_study;
  } else if (details && details.entry_level) {
    return details.entry_level;
  }

  return null;
}

export function startDate(application) {
  const details = application.application_detail && application.application_detail.applicant_details;
  const offer = application.offer;

  if (offer && offer.confirmed_offer_details && offer.confirmed_offer_details.study_start_date) {
    return format(parseISO(convertRFCtoISO(offer.confirmed_offer_details.study_start_date)), DATE_DASH_FORMAT);
  } else if (offer && offer.create_offer && offer.create_offer.study_start_date) {
    return format(parseISO(convertRFCtoISO(offer.create_offer.study_start_date)), DATE_DASH_FORMAT);
  } else if (details && details.intended_start_date) {
    return format(parseISO(convertRFCtoISO(details.intended_start_date)), DATE_DASH_FORMAT);
  } else if (details && details.intended_start_date_month && details.intended_start_date_year) {
    return format(new Date(`01 ${details.intended_start_date_month} ${details.intended_start_date_year}`), DATE_DASH_FORMAT);
  }

  return null;
}

export function endDate(application) {
  const details = application.application_detail && application.application_detail.applicant_details;
  const offer = application.offer;

  if (offer && offer.confirmed_offer_details && offer.confirmed_offer_details.study_end_date) {
    return format(parseISO(convertRFCtoISO(offer.confirmed_offer_details.study_end_date)), DATE_DASH_FORMAT);
  } else if (offer && offer.create_offer && offer.create_offer.study_end_date) {
    return format(parseISO(convertRFCtoISO(offer.create_offer.study_end_date)), DATE_DASH_FORMAT);
  } else if (details && details.intended_end_date) {
    return format(parseISO(convertRFCtoISO(details.intended_end_date)), DATE_DASH_FORMAT);
  } else if (details && details.intended_end_date_month && details.intended_end_date_year) {
    return format(new Date(`01 ${details.intended_end_date_month} ${details.intended_end_date_year}`), DATE_DASH_FORMAT);
  }

  return null;
}
