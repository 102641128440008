import { intlFormat, parseISO } from 'date-fns';
import Loader from 'components/Loader';
import { FormLabel, Stack, Typography } from '@mui/material';
import { useLocale } from '../../../contexts/LocaleContext';

const ApplicantDetailItem = ({ label, value }) => {
  return (
    <Stack gap={1}>
      <FormLabel>{label}</FormLabel>
      <Typography variant="body2">{value}</Typography>
    </Stack>
  );
};

interface ApplicantDetailsFieldProps {
  firstName?: string;
  lastName?: string;
  gender?: 'Male' | 'Female' | 'Diverse';
  dateOfBirth?: string;
  nationality?: string;
}

export const ApplicantDetailsField = ({ firstName, lastName, gender, dateOfBirth, nationality }: ApplicantDetailsFieldProps) => {
  const { localeCode } = useLocale();

  if (!firstName || !lastName || !gender || !dateOfBirth) {
    return <Loader />;
  }
  return (
    <Stack direction="row" justifyContent="space-between" data-cy-field-type="applicant-details">
      <ApplicantDetailItem label="Student name" value={`${firstName} ${lastName}`} />
      <ApplicantDetailItem label="Gender" value={gender} />
      <ApplicantDetailItem label="Date of birth" value={intlFormat(parseISO(dateOfBirth), { locale: localeCode })} />
      <ApplicantDetailItem label="Nationality" value={nationality || 'Unknown'} />
    </Stack>
  );
};

// for React.lazy
export default ApplicantDetailsField;
