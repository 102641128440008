import { WorkflowActionList } from './WorkflowActionList';
import { POS_PIPELINE } from 'workflow/actions/constants/Actions';
import { FormBuilderAction } from '../../FormBuilder/types/FormBuilderAction';
import { Application } from '../../../types/Application';

interface NewWorkflowActionsProps {
  actions: FormBuilderAction[];
  workflowEndpoint: string;
  application?: Application;
}

export const NewWorkflowActions = ({ actions, workflowEndpoint, application }: NewWorkflowActionsProps) => {
  if (!application) return <></>;

  const filteredActions = actions.filter((action) => action.position === POS_PIPELINE);
  return <WorkflowActionList actions={filteredActions} workflowEndpoint={workflowEndpoint} />;
};
