import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useAppDispatch } from 'app/hooks';
import { resetSchool, selectSchool } from 'app/actions/schoolActions';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { useMountEffect } from 'hooks/useMountEffect';
import { interleaveMenuDivider } from 'utils/interleaveDivider';
import { usePermissions } from 'contexts/PermissionContext';
import {
  ROLE_TYPE_GREENHEART_ACCTMGMT_ADMIN,
  ROLE_TYPE_GREENHEART_ACCTMGMT_STAFF,
  ROLE_TYPE_GREENHEART_ADMIN,
  ROLE_TYPE_GREENHEART_COMPLIANCE_ADMIN,
  ROLE_TYPE_GREENHEART_COMPLIANCE_STAFF,
  ROLE_TYPE_GREENHEART_ENROLMENT_ADMIN,
  ROLE_TYPE_GREENHEART_ENROLMENT_STAFF,
  ROLE_TYPE_GREENHEART_ONPROGRAM_ADMIN,
  ROLE_TYPE_GREENHEART_ONPROGRAM_STAFF,
} from 'permissions/constants/Roles';

interface UserSchool {
  id: string;
  name: string;
  slug: string;
  logo: string;
  color: string;
  external?: unknown;
  domain?: string;
}

export const SchoolSelectorDropdownButton = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { hasRole } = usePermissions();

  const isGreenheartStaff = hasRole([
    ROLE_TYPE_GREENHEART_ADMIN,
    ROLE_TYPE_GREENHEART_ENROLMENT_ADMIN,
    ROLE_TYPE_GREENHEART_COMPLIANCE_STAFF,
    ROLE_TYPE_GREENHEART_ONPROGRAM_STAFF,
    ROLE_TYPE_GREENHEART_ACCTMGMT_ADMIN,
    ROLE_TYPE_GREENHEART_COMPLIANCE_ADMIN,
    ROLE_TYPE_GREENHEART_ACCTMGMT_STAFF,
    ROLE_TYPE_GREENHEART_ENROLMENT_STAFF,
    ROLE_TYPE_GREENHEART_ONPROGRAM_ADMIN,
  ]);

  const handleClickActiveSchool = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!hasMultipleSchools) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectSchool = (school: UserSchool) => () => {
    handleClose();
    dispatch(resetSchool());
    const homeUrl = isGreenheartStaff ? 'participants' : 'pipeline';
    if (school.external) {
      window.location.href = `${school.domain}/${school.slug}/${homeUrl}`;
    } else {
      dispatch(selectSchool(school));
      const next = new URLSearchParams(window.location.search).get('next') || homeUrl;
      navigate(`/${school.slug}/${next}`);
    }
  };

  const sortNameAlphabetic = (a: UserSchool, b: UserSchool) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());

  const [userSchools, setUserSchools] = useState<UserSchool[]>([]);
  const { showErrorMessage } = useNotificationMessages();
  const getUserSchools = useStatelessGet<UserSchool[]>(`/user-schools`);
  const loadUserSchools = useCallback(() => {
    getUserSchools()
      .then((response) => response && setUserSchools(response.sort(sortNameAlphabetic)))
      .catch((error) => showErrorMessage(getErrorMessage(error)));
  }, [getUserSchools, showErrorMessage]);
  useMountEffect(loadUserSchools);

  const hasMultipleSchools = userSchools.length > 1;

  if (!hasMultipleSchools) return <></>;

  const filteredSchools = userSchools.filter((school) => school.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <>
      <IconButton
        id="school-selector"
        aria-controls={open ? 'school-selector-menu' : undefined}
        aria-haspopup={hasMultipleSchools}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickActiveSchool}
        sx={{ color: (theme) => theme.palette.grey[500] }}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        id="school-selector-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
          'aria-labelledby': 'school-selector',
        }}
      >
        <Stack px={2} mb={1} component="li" key="provider-search-box">
          <Typography variant="subtitle2">Change Provider:</Typography>
          <TextField
            fullWidth
            autoFocus
            size="small"
            placeholder="Search..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            InputProps={{
              endAdornment: (
                <IconButton sx={{ visibility: searchTerm ? 'visible' : 'hidden' }} onClick={() => setSearchTerm('')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Stack>
        {interleaveMenuDivider(
          filteredSchools.map((school: UserSchool, index) => (
            <MenuItem key={school.id} onClick={handleSelectSchool(school)} tabIndex={index}>
              <Stack direction="row" alignItems="center" spacing={2} pr={1}>
                <Avatar
                  src={school.logo}
                  alt={`${school.name} logo`}
                  variant="square"
                  sx={{
                    bgcolor: school.color,
                    width: 24,
                    height: 24,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AccountBalanceIcon sx={{ width: '20px', height: '20px' }} />
                </Avatar>
                <Typography>{school.name}</Typography>
              </Stack>
            </MenuItem>
          )),
        )}
      </Menu>
    </>
  );
};
