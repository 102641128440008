import { AppBar, Avatar, Box, Divider, Link, Stack, Toolbar, useTheme } from '@mui/material';
import HasAccess from 'permissions/HasAccess';
import {
  ROLE_TYPE_APPLICANT,
  ROLE_TYPE_GREENHEART_ACCTMGMT_ADMIN,
  ROLE_TYPE_GREENHEART_ACCTMGMT_STAFF,
  ROLE_TYPE_GREENHEART_ADMIN,
  ROLE_TYPE_GREENHEART_COMPLIANCE_ADMIN,
  ROLE_TYPE_GREENHEART_COMPLIANCE_STAFF,
  ROLE_TYPE_GREENHEART_ENROLMENT_ADMIN,
  ROLE_TYPE_GREENHEART_ENROLMENT_STAFF,
  ROLE_TYPE_GREENHEART_ONPROGRAM_ADMIN,
  ROLE_TYPE_GREENHEART_ONPROGRAM_STAFF,
  ROLE_TYPE_HOSTORG_ADMIN,
  ROLE_TYPE_HOSTORG_STAFF,
  ROLE_TYPE_PARTNER_ADMIN,
  ROLE_TYPE_PARTNER_STAFF,
} from 'permissions/constants/Roles';
import goLogo from './go-logo.svg';
import { isMenuItemActive } from '../../utils/isMenuItemActive';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from '../../app/routes/SchoolRoutes';
import { ProfileMenu } from '../../components/Menu/ProfileMenu';

const GreenheartMenuItem = ({ url, label }: { url: string; label: string }) => {
  const theme = useTheme();
  const menuLinkSx = (active: boolean) => ({
    px: 2,
    color: theme.palette.primary.main,
    height: '80px',
    lineHeight: '80px',
    fontSize: '2rem',
    borderBottomWidth: '5px',
    borderBottomStyle: 'solid',
    borderBottomColor: active ? theme.palette.primary.light : 'transparent',
  });

  return (
    <Link href={url} sx={menuLinkSx(isMenuItemActive(url))}>
      {label}
    </Link>
  );
};

const HostOrgPartnerMenu = () => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;

  return (
    <>
      <GreenheartMenuItem url={`/${schoolSlug}/positions`} label="Positions" />
      <HasAccess for="role" name={[ROLE_TYPE_PARTNER_STAFF, ROLE_TYPE_PARTNER_ADMIN]}>
        <GreenheartMenuItem url={`/${schoolSlug}/participants`} label="Participants" />
      </HasAccess>
    </>
  );
};

const ParticipantMenu = () => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const url = schoolSlug ? `/${schoolSlug}/pipeline` : '/';
  return <GreenheartMenuItem url={url} label="Placement" />;
};

const GreenheartStaffMenu = () => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;

  return (
    <>
      <GreenheartMenuItem url={`/${schoolSlug}/positions`} label="Positions" />
      <GreenheartMenuItem url={`/${schoolSlug}/participants`} label="Participants" />
    </>
  );
};

export const GreenheartHeader = () => {
  const theme = useTheme();
  const bgcolor = theme.palette.background.paper;
  const color = theme.palette.getContrastText(bgcolor);
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;

  return (
    <AppBar sx={{ color, bgcolor }}>
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          <Avatar src={goLogo} alt="Brand logo" variant="square" sx={{ width: '64.5px', height: '50px' }} />
          <Box px={3}>
            <Divider orientation="vertical" variant="middle" sx={{ height: '32px', borderWidth: '1px' }} />
          </Box>
          <Box flexGrow="1" />
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            <HasAccess for="role" name={[ROLE_TYPE_HOSTORG_STAFF, ROLE_TYPE_HOSTORG_ADMIN, ROLE_TYPE_PARTNER_STAFF, ROLE_TYPE_PARTNER_ADMIN]}>
              <HostOrgPartnerMenu />
            </HasAccess>
            <HasAccess for="role" name={[ROLE_TYPE_APPLICANT]}>
              <ParticipantMenu />
            </HasAccess>
            <HasAccess
              for="role"
              name={[
                ROLE_TYPE_GREENHEART_ADMIN,
                ROLE_TYPE_GREENHEART_ENROLMENT_ADMIN,
                ROLE_TYPE_GREENHEART_COMPLIANCE_STAFF,
                ROLE_TYPE_GREENHEART_ONPROGRAM_STAFF,
                ROLE_TYPE_GREENHEART_ACCTMGMT_ADMIN,
                ROLE_TYPE_GREENHEART_COMPLIANCE_ADMIN,
                ROLE_TYPE_GREENHEART_ACCTMGMT_STAFF,
                ROLE_TYPE_GREENHEART_ENROLMENT_STAFF,
                ROLE_TYPE_GREENHEART_ONPROGRAM_ADMIN,
              ]}
            >
              <GreenheartStaffMenu />
            </HasAccess>
            <ProfileMenu slug={schoolSlug} />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
