import { AppBar, Avatar, Box, Divider, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HasAccess from 'permissions/HasAccess';
import {
  ROLE_TYPE_AGENT,
  ROLE_TYPE_APPLICANT,
  ROLE_TYPE_ENROLLER_ADMIN,
  ROLE_TYPE_INSTITUTION_ADMIN,
  ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF,
  ROLE_TYPE_INSTITUTION_STAFF,
  ROLE_TYPE_PARENT,
} from 'permissions/constants/Roles';
import { Menu } from 'components/Menu/Menu';
import { UserMenu } from 'components/Menu/UserMenu';
import { useAppSelector } from 'app/hooks';
import { SchoolSelectorDropdownButton } from '../../components/AppLayout/SchoolSelectorDropdownButton';
import { useAuth0 } from '@auth0/auth0-react';
import defaultLogo from '../../images/icons/favicon-180.png';

export const EnrollerHeader = () => {
  const theme = useTheme();
  const { user } = useAuth0();
  const bgcolor = theme.palette.background.paper;
  const color = theme.palette.getContrastText(bgcolor);

  const activeSchoolName = useAppSelector((state) => state.school.name);
  const activeSchoolLogo = useAppSelector((state) => state.school.logo);
  const activeSchoolColor = useAppSelector((state) => state.school.color);

  return (
    <AppBar sx={{ color, bgcolor }}>
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          <Avatar src={defaultLogo} alt="Brand logo" variant="square" sx={{ width: '32px', height: '32px' }} />
          <Box px={3}>
            <Divider orientation="vertical" variant="middle" sx={{ height: '32px', borderWidth: '1px' }} />
          </Box>
          <Avatar
            src={activeSchoolLogo}
            alt={`${activeSchoolName} logo`}
            variant="square"
            sx={{
              bgcolor: activeSchoolColor,
              width: '32px',
              height: '32px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AccountBalanceIcon />
          </Avatar>
          <Typography fontSize="20px" component="div" mx={2} sx={{ color: '#1E1E1E' }}>
            {activeSchoolName}
          </Typography>
          {!!user && <SchoolSelectorDropdownButton />}
          <Box flexGrow="1" />
          <Stack justifyContent="center">
            {/* TODO: Rewrite nav links from scratch without using config/routes.ts */}
            <HasAccess
              for="role"
              name={[
                ROLE_TYPE_ENROLLER_ADMIN,
                ROLE_TYPE_INSTITUTION_ADMIN,
                ROLE_TYPE_INSTITUTION_STAFF,
                ROLE_TYPE_INSTITUTION_READ_ONLY_STAFF,
                ROLE_TYPE_AGENT,
                ROLE_TYPE_PARENT,
                ROLE_TYPE_APPLICANT,
              ]}
              fallback={<UserMenu />}
            >
              <Menu />
            </HasAccess>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
