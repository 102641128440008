import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

const shareAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/share/{user}`,
});

const api = {
  POST: shareAPI.wrap(defaultRequest, { method: 'POST' }),

  PATCH: shareAPI.wrap(defaultRequest, { method: 'PATCH' }),

  DELETE: shareAPI.wrap(defaultRequest, {
    method: 'DELETE',
    path: `${apiUrl}/schools/{slug}/share/{application}/{user}`,
  }),
};

export default api;
