import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import HasFeature from 'businessToggles/HasFeature';
import { useWorkflow } from 'contexts/WorkflowContext';
import Content from 'components/Translation/Content';
import StageColumns from 'views/Steps/StageColumns';
import StageScroller from 'views/Steps/StageScroller';
import { FLAG_ENQUIRE_NOW } from 'businessToggles/Flags';
import { useAuth0 } from '@auth0/auth0-react';
import { usePageTitle } from 'contexts/PageTitleContext';
import { Box, Button, Stack, Typography } from '@mui/material';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { useStatelessPut } from 'hooks/useStatelessPut';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthUser } from 'utils/user';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import defaultLogo from 'images/icons/favicon-180.png';
import enrollerLogo from 'images/logo-enroller.svg';

const LogoHolder = styled.div`
  width: 23.4rem;
  height: 24rem;
  margin: 0 auto;
  background: transparent url('${(props: any) => props.img}') 50% 50% no-repeat;
  background-size: contain;
`;

const EnrollerLogoHolder = styled.img`
  width: 30rem;
  margin: 10rem auto 5rem;
`;

const IntroContent = styled.div`
  p {
    width: 100%;
    font-size: 2.4rem;
    line-height: 3rem;
  }
`;

export const StartScreen = () => {
  const { isAuthenticated, user } = useAuth0<AuthUser>();
  const { slug } = useParams() as SchoolRouteParams;
  const schoolName = useAppSelector((state) => state.school.name);
  const schoolLogo = useAppSelector((state) => state.school.logo);
  const schoolIntroContent = useAppSelector((state) => state.school.intro_content);
  const {
    state: { workflow },
  } = useWorkflow();
  const { setPageTitle } = usePageTitle();
  const linkUserToSchool = useStatelessPut(`/schools/${slug}/enrolment-application`);
  const [isItLoading, setIsItLoading] = useState(false);
  const navigate = useNavigate();

  const putLinkUserToSchool = async () => {
    const userIsLinkedToSchool = user?.userData.schoolSlugs.includes(slug);

    if (!userIsLinkedToSchool) {
      setIsItLoading(true);
      await linkUserToSchool();
      setIsItLoading(false);
    }

    navigate(`/${slug}/pipeline`);
  };

  useEffect(() => setPageTitle(`${schoolName} | New Application`), [schoolName, setPageTitle]);

  const renderEnquiryButton = () => {
    return (
      <HasFeature name={[FLAG_ENQUIRE_NOW]}>
        <Button variant="contained" component={Link} to={`/${slug}/enquire`}>
          Enquire now
        </Button>
      </HasFeature>
    );
  };

  const renderButton = () => {
    if (!isAuthenticated) {
      return (
        <Link to={`/${slug}/register`}>
          <Button variant="contained">Start now</Button>
        </Link>
      );
    }

    return (
      <LoadingButton variant="contained" loading={isItLoading} onClick={putLinkUserToSchool}>
        Start now
      </LoadingButton>
    );
  };

  return (
    <Grid container justifyContent="center" pt={2}>
      <Grid lg={12} xl={10}>
        {slug === 'enroller-pathways' ? (
          <EnrollerLogoHolder src={enrollerLogo} width="300px" />
        ) : (
          // @ts-expect-error TS(2769): No overload matches this call.
          <LogoHolder img={schoolLogo || defaultLogo} />
        )}
        <Typography component="h1" variant="h1" sx={{ textAlign: 'center', mb: 3 }}>
          Apply to {schoolName}
        </Typography>
        {schoolIntroContent ? (
          <IntroContent
            dangerouslySetInnerHTML={{
              __html: schoolIntroContent,
            }}
          />
        ) : (
          <Box sx={{ lineHeight: '3rem', fontSize: '2.4rem', textAlign: 'center', mb: 2 }}>
            <Content name="application.welcome">
              Welcome! This is our online enrolment hub, it is the place you will visit to review and action each stage of the application process explained
              below.
            </Content>
          </Box>
        )}
        <Stack direction="row" spacing={2} justifyContent="center">
          {renderEnquiryButton()}
          {renderButton()}
        </Stack>
        {workflow?.stages?.length && (
          <Grid>
            <StageScroller>
              <StageColumns stages={workflow.stages} />
            </StageScroller>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
