import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { usePost } from 'hooks/usePost';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as SwapIcon } from 'images/icons/icon-swap.svg';
import { Controller, useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils/errors';

interface CreateTicketProps {
  actionId: string;
  workflowEndpoint: string;
  label: string;
  disabled?: boolean;
  categoryOptions: any[];
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const CreateTicket = ({ actionId, workflowEndpoint, label, disabled, color, variant, categoryOptions }: CreateTicketProps) => {
  const theme = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitting, postTicket] = usePost<ResponseEnvelope<any>>(`${workflowEndpoint}/actions/${actionId}`);
  const { showSuccessMessage, showErrorMessage } = useNotificationMessages();

  const onSubmit = (payload) => {
    postTicket(payload)
      .then(async (response) => {
        const { success, message } = response;

        if (success) {
          handleToggle();
          showSuccessMessage(message);
        } else {
          showErrorMessage(message);
        }
      })
      .catch((error) => {
        showErrorMessage(getErrorMessage(error));
      });
  };

  interface CreateTicketFormValues {
    category: string;
    description: string;
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<CreateTicketFormValues>({
    defaultValues: { category: '', description: '' },
  });

  const handleToggle = () => {
    setShowDialog(!showDialog);
    reset();
  };

  return (
    <>
      <LoadingButton variant={variant} color={color} type="submit" loading={isSubmitting} disabled={disabled} onClick={handleToggle}>
        {label}
      </LoadingButton>

      <Dialog open={showDialog} onClose={handleToggle}>
        <DialogTitle>
          <Stack justifyContent="space-between" gap={2}>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.light,
                p: 2,
                width: 'fit-content',
                borderRadius: '99px',
                lineHeight: theme.spacing(3),
                fontSize: theme.spacing(4),
              }}
            >
              <SwapIcon width={theme.spacing(3)} height={theme.spacing(3)} color={theme.palette.primary.dark} />
            </Box>
            <Typography variant="h5" component="h2">
              Request Changes to Program
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" justifyContent="space-between" gap={2} sx={{ width: '100%', mb: 5 }}>
              <Controller
                name="category"
                control={control}
                rules={{ required: 'Category is required' }}
                render={({ field: { value, onChange } }) => (
                  <FormControl error={!!errors.category}>
                    <FormLabel id="categoryLabel" sx={{ mb: 1 }}>
                      Select change category
                    </FormLabel>
                    <Select
                      aria-labelledby="categoryLabel"
                      size="small"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      error={!!errors.category}
                    >
                      {categoryOptions.map(({ value }) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.category && <Typography color="error">{errors.category.message}</Typography>}
                  </FormControl>
                )}
              />

              <Controller
                name="description"
                control={control}
                rules={{ required: 'Description is required' }}
                render={({ field: { value, onChange } }) => (
                  <FormControl error={!!errors.category}>
                    <FormLabel id="descriptionLabel" sx={{ mb: 1 }}>
                      Please explain your circumstances and reasons for the change request
                    </FormLabel>
                    <TextField
                      size="small"
                      multiline
                      rows={3}
                      aria-labelledby="descriptionLabel"
                      value={value}
                      onChange={onChange}
                      error={!!errors.description}
                    />
                    {errors.description && <Typography color="error">{errors.description.message}</Typography>}
                  </FormControl>
                )}
              />

              <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleToggle}>
                  Cancel
                </Button>
                <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Request change
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
