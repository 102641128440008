import { getConfig } from 'utils/config';
import { SxProps, Theme, Typography } from '@mui/material';
import { useLocale } from 'contexts/LocaleContext';

export const T = (props) => {
  const { name, className, ...params } = props;
  const { locale } = useLocale();
  const { environment } = getConfig();
  const { getTranslationForKey } = useLocale();

  if (locale === 'en') return <></>;

  const translation = getTranslationForKey(name, params);
  const showDebug = (environment === 'development' || environment === 'local') && ((!translation && translation !== '') || translation === name);

  const sxProps: SxProps<Theme> = {
    display: 'inline',
    sx: { color: (theme) => theme.palette.grey[700] },
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    fontWeight: 300,
    m: 0,
  };

  if (showDebug) {
    return (
      <>
        <Typography {...sxProps} color="warn">
          {`${name} is undefined for`}
        </Typography>
        <Typography {...sxProps} color="warn" fontWeight="bold">
          {locale}
        </Typography>
      </>
    );
  }
  if (translation) {
    return <Typography data-locale={locale} lang={locale} className={className} dangerouslySetInnerHTML={{ __html: translation }} {...sxProps} />;
  }
  return <></>;
};
