import { AuthUser } from './user';
import {
  GROUP_TYPE_INSTITUTION,
  ROLE_TYPE_AGENT,
  ROLE_TYPE_APPLICANT,
  ROLE_TYPE_ENROLLER_ADMIN,
  ROLE_TYPE_GREENHEART_ACCTMGMT_ADMIN,
  ROLE_TYPE_GREENHEART_ACCTMGMT_STAFF,
  ROLE_TYPE_GREENHEART_ADMIN,
  ROLE_TYPE_GREENHEART_COMPLIANCE_ADMIN,
  ROLE_TYPE_GREENHEART_COMPLIANCE_STAFF,
  ROLE_TYPE_GREENHEART_ENROLMENT_ADMIN,
  ROLE_TYPE_GREENHEART_ENROLMENT_STAFF,
  ROLE_TYPE_GREENHEART_ONPROGRAM_ADMIN,
  ROLE_TYPE_GREENHEART_ONPROGRAM_STAFF,
  ROLE_TYPE_HOSTORG_ADMIN,
  ROLE_TYPE_HOSTORG_STAFF,
  ROLE_TYPE_PARENT,
  ROLE_TYPE_PARTNER_ADMIN,
  ROLE_TYPE_PARTNER_STAFF,
  ROLE_TYPE_SHARED_APPLICANT,
} from '../permissions/constants/Roles';

export const getLandingPage = (user: AuthUser, hasGroup: (groups: string[]) => boolean, hasRole: (roles: string[]) => boolean, schoolSlug?: string): string => {
  // TODO: when preferred school added use that for all
  // if we are on a school route, or the user only has 1 associated school
  const redirectSchoolSlug: string | null = schoolSlug || user.userData.schoolSlugs.length === 1 ? user.userData.schoolSlugs[0] : null;

  // admin user goes to workflow management
  if (user.userData.roles.includes(ROLE_TYPE_ENROLLER_ADMIN)) {
    return '/management/workflows';
  }

  // school based landing pages
  if (redirectSchoolSlug) {
    if (hasRole([ROLE_TYPE_HOSTORG_ADMIN, ROLE_TYPE_HOSTORG_STAFF])) {
      return `/${redirectSchoolSlug}/positions`;
    }
    const isNonInstitutionalUser = hasRole([ROLE_TYPE_AGENT, ROLE_TYPE_PARENT, ROLE_TYPE_APPLICANT]);
    const isGreenheartStaff = hasRole([
      ROLE_TYPE_GREENHEART_ADMIN,
      ROLE_TYPE_GREENHEART_ENROLMENT_ADMIN,
      ROLE_TYPE_GREENHEART_COMPLIANCE_STAFF,
      ROLE_TYPE_GREENHEART_ONPROGRAM_STAFF,
      ROLE_TYPE_GREENHEART_ACCTMGMT_ADMIN,
      ROLE_TYPE_GREENHEART_COMPLIANCE_ADMIN,
      ROLE_TYPE_GREENHEART_ACCTMGMT_STAFF,
      ROLE_TYPE_GREENHEART_ENROLMENT_STAFF,
      ROLE_TYPE_GREENHEART_ONPROGRAM_ADMIN,
    ]);
    const isGreenheartPartner = hasRole([ROLE_TYPE_PARTNER_ADMIN, ROLE_TYPE_PARTNER_STAFF]);
    if (isGreenheartStaff || isGreenheartPartner) {
      return `/${redirectSchoolSlug}/participants`;
    }
    if (isNonInstitutionalUser) {
      return `/${redirectSchoolSlug}/pipeline`;
    }
    if (hasGroup([GROUP_TYPE_INSTITUTION])) {
      return `/${redirectSchoolSlug}/dashboard`;
    }
  }

  if (user.userData.roles.includes(ROLE_TYPE_SHARED_APPLICANT)) {
    // If the shared user only has one shared application, just take them directly to it
    if (user.userData.sharedUserApplications.length === 1) {
      const sharedApplication = user.userData.sharedUserApplications[0];
      return `/${sharedApplication.school.slug}/enrolment-application/applicant/${sharedApplication.applicant_id}/application/${sharedApplication.id}/stages`;
    } else {
      return '/shared-enrolments';
    }
  }

  return '/my-schools';
};
