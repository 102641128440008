import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import formDataInterceptor from 'app/api/common/formDataInterceptor';
import { getConfig } from '../../utils/config';

const { apiUrl } = getConfig();

const schoolAPI = client
  .wrap(formDataInterceptor)
  .wrap(template)
  .wrap(defaultRequest, {
    path: `${apiUrl}/schools/{slug}/settings`,
  });

const schoolXeroAPI = client.wrap(template).wrap(defaultRequest, {
  path: `${apiUrl}/schools/{slug}/xero/{query}`,
});

schoolXeroAPI.GET = schoolXeroAPI.wrap(defaultRequest, { method: 'GET' });

const api = {
  // GET|HEAD  | api/schools/{school}              | schools
  GET: schoolAPI.wrap(defaultRequest, { method: 'GET' }),

  // POST      | api/schools/{school}              | schools
  POST: schoolAPI.wrap(defaultRequest, { method: 'POST' }),

  // PUT      | api/schools/{school}              | schools
  PUT: schoolAPI.wrap(defaultRequest, { method: 'PUT' }),
};

export { schoolXeroAPI };
export default api;
