import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Payment } from 'types/Invoice';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { useDelete } from '../../hooks/useDelete';
import { useAppSelector } from 'app/hooks';
import currency from 'currency/currency';
import { useLocale } from 'contexts/LocaleContext';

interface DeletePaymentDialogProps {
  payment: Payment | null;
  active: boolean;
  onDeletePayment: () => void;
  onClose: () => void;
}

export const DeletePaymentDialog = ({ payment, active, onDeletePayment, onClose }: DeletePaymentDialogProps) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;

  const { locale } = useLocale();
  const currencyCode = useAppSelector((state) => state.school.currency_code);

  const deletePaymentUrl = `/schools/${schoolSlug}/payments/${payment?.id}`;
  const [loadingDelete, deletePayment] = useDelete<ResponseEnvelope<any>>(deletePaymentUrl);

  const handleDelete = () => {
    deletePayment().then(() => {
      onDeletePayment();
      onClose();
    });
  };

  return (
    <Dialog open={active} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Delete payment
          </Typography>
          <IconButton aria-label="close" title="Close" onClick={onClose} sx={{ color: (theme) => theme.palette.grey[500] }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography display="inline">Are you sure you want to delete this payment of </Typography>
        <Typography display="inline" fontWeight="bold">
          {currency(payment?.amount, currencyCode, locale)}
        </Typography>
        <Typography display="inline"> ?</Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" type="submit" loading={loadingDelete} onClick={handleDelete} color="error">
          Delete
        </LoadingButton>
        <Button onClick={onClose} color="warning">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
