import { Box, CircularProgress, Stack, Typography } from '@mui/material';

interface LoaderProps {
  small?: boolean;
  center?: boolean;
  contained?: boolean;
  fullPage?: boolean;
}

export const Loader = ({ small = false, center = false, contained = false, fullPage = false }: LoaderProps) => {
  const progressSize = small || contained ? '2rem' : '4rem';

  if (fullPage) {
    return (
      <Box flex={1}>
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
          <CircularProgress size={progressSize} variant="indeterminate" />
          <Typography>Loading&hellip;</Typography>
        </Stack>
      </Box>
    );
  }

  // loader with old styles
  return (
    <Box
      sx={{
        float: center ? 'left' : undefined,
        margin: center ? '0 auto' : '0',
        width: small || contained ? '3rem' : '6rem',
        height: small || contained ? '3rem' : '6rem',
        pt: small ? '0.9rem' : '0',
        flex: 1,
      }}
    >
      <CircularProgress size={progressSize} variant="indeterminate" />
    </Box>
  );
};

export default Loader;
