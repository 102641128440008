import { createContext } from 'react';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { loginConfig } from 'auth0/login-config';
import Loader from 'components/Loader';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { hide } from 'app/actions/errorActions';
import { PermissionProvider } from 'contexts/PermissionContext';
import { SnackbarKey } from 'notistack';

const initialContext = {
  errors: null,
  operations: {
    onError: (e) => {},
    onSuccess: (() => {}) as unknown as (message: string) => SnackbarKey,
  },
  headers: {} as {
    Authorization: `Bearer ${string}`;
    Accept: 'application/json';
  },
};

export const AppContext = createContext(initialContext);

AppContext.displayName = 'AppContext';

export const AppProvider = ({ children }) => {
  const [errors, setErrors] = useState(null);
  const [authToken, setAuthToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector((state) => state.errors.errorMessage);
  const successMessage = useAppSelector((state) => state.forms.successMessage);
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  useEffect(() => {
    if (errorMessage) {
      showErrorMessage(errorMessage);
      dispatch(hide());
    }
  }, [dispatch, errorMessage, showErrorMessage]);

  useEffect(() => {
    if (successMessage) {
      showSuccessMessage(successMessage);
    }
  }, [dispatch, showSuccessMessage, successMessage]);

  useEffect(() => {
    const fetchToken = async () => {
      setIsLoading(true);

      try {
        const token = await getAccessTokenSilently();

        if (token) {
          setAuthToken(token);
        }
      } catch {
        loginWithRedirect(loginConfig(window.location.pathname));
      }

      setIsLoading(false);
    };

    if (!isAuthLoading) {
      if (isAuthenticated) {
        fetchToken();
      } else {
        setIsLoading(false);
      }
    }
  }, [getAccessTokenSilently, loginWithRedirect, isAuthenticated, isAuthLoading]);

  const handleError = (error) => {
    if (error.status === 401) {
      showErrorMessage('401 unauthorised, you need to login to access');
    }

    if (error.status === 403) {
      showErrorMessage('403 forbidden, you cannot access this resource');
    }

    if (error.data && error.data.message) {
      showErrorMessage(error.data.message);
    } else if (error.message) {
      showErrorMessage(error.message);
    } else if (typeof error === 'string') {
      showErrorMessage(error);
    } else {
      showErrorMessage('An error has occured');
      console.error(error);
    }

    if (error && error.data && 'errors' in error.data) {
      setErrors(error.data.errors);
    }
  };

  if (isAuthLoading || isLoading) {
    return <Loader fullPage />;
  }

  return (
    <AppContext.Provider
      value={{
        errors,
        operations: {
          onSuccess: showSuccessMessage,
          onError: handleError,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
        },
      }}
    >
      <PermissionProvider>{children}</PermissionProvider>
    </AppContext.Provider>
  );
};
