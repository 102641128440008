import { useState } from 'react';
import styled from 'styled-components';
import { intlFormat, parseISO } from 'date-fns';
import { isExpired } from 'utils/isExpired';
import { ShareApplicationDialog } from 'sharedApplication/ShareApplicationDialog';
import { useApplication } from 'contexts/ApplicationContext';
import { Button } from '@mui/material';
import { useLocale } from '../contexts/LocaleContext';

const ExpiredInfo = styled.div`
  margin-top: 0.5rem;
  text-align: left;
  font-size: 1.4rem;
  color: ${(props) => props.theme.overdue};

  @media ${(props) => props.theme.md} {
    text-align: center;
  }
`;

export const ShareApplicationButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { localeCode } = useLocale();
  const {
    state: { application },
    loadApplication,
  } = useApplication();
  const sharedWith = application?.shared_application_users?.[0];

  return (
    <div>
      <Button variant="contained" onClick={() => setShowDialog(true)} disabled={!application}>
        {sharedWith ? `Shared with ${sharedWith.email}` : 'Share Application'}
      </Button>
      {sharedWith && isExpired(sharedWith.expiry_date) && (
        <ExpiredInfo>Access expired on {intlFormat(parseISO(sharedWith.expiry_date), { locale: localeCode })}</ExpiredInfo>
      )}
      <ShareApplicationDialog
        active={showDialog}
        applicationId={application?.id}
        onShareApplication={loadApplication}
        onToggle={() => setShowDialog(!showDialog)}
        share={sharedWith}
      />
    </div>
  );
};
