import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'app/PrivateRoute';
import { StartApplication } from 'views/Applicants/StartApplication';
import { Workflow } from 'components/Workflow/Workflow';
import { StartScreen } from 'workflow/views/StartScreen';
import React from 'react';
import { ApplicantProvider } from 'contexts/ApplicantContext';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { AppLayout } from '../../components/AppLayout/AppLayout';

export interface SchoolWorkflowRouteParams extends SchoolRouteParams {
  applicant: string;
}

export const SchoolWorkflowRoutes = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/" element={<StartScreen />} />
      </Route>
      <Route
        element={
          <PrivateRoute>
            <ApplicantProvider>
              <AppLayout />
            </ApplicantProvider>
          </PrivateRoute>
        }
      >
        <Route path="applicant" element={<StartApplication />} />
        <Route path="applicant/:applicant/*" element={<Workflow />} />
      </Route>
    </Routes>
  );
};
