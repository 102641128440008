import { MaterialReactTableProps, MRT_ColumnDef, MRT_PaginationState, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useQuery } from '@tanstack/react-query';
import { PaginatedEnvelope } from '../types/PaginatedEnvelope';

interface useDataTableOptions<T extends Record<string, any>> {
  columnDefinitions: MRT_ColumnDef<T>[];
  tableKey: string;
  rowActions?: MaterialReactTableProps<T>['renderRowActionMenuItems'];
  globalFilter: string;
  urlParams?: URLSearchParams;
  getTableData: (params: URLSearchParams) => Promise<PaginatedEnvelope<T>>;
}

/**
 * Hook to prime config for use in DataTable. Data table is separated into 2 parts to allow return of the `refetch`
 * function to be used to trigger table refreshes when we know data is modified, e.g. deleting a row.
 *
 * // configure a table using the hook
 * const { table, refetch } = useDataTable<any>({ ...config... });
 * // ...and later pass table instance to the DataTable component
 * <DataTable
 *   table={table}
 *   refetch={refetch}
 *   ...props...
 * />
 *
 * @see src/components/DataTable/DataTable.tsx
 * @see src/views/Settings/Agents/AgentsTable.tsx
 */
export const useDataTable = <T extends Record<string, any>>({
  columnDefinitions,
  tableKey,
  rowActions,
  globalFilter,
  urlParams,
  getTableData,
}: useDataTableOptions<T>) => {
  const theme = useTheme();
  const [debouncedSearchTerm] = useDebounce<string>(globalFilter, 400);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryFn = useCallback(async () => {
    const searchParams = urlParams || new URLSearchParams();
    searchParams.set('page', `${pagination.pageIndex + 1}`);
    searchParams.set('size', `${pagination.pageSize}`);
    const { id: sortKey, desc } = sorting[0] || {};
    if (sortKey) {
      searchParams.set('sort', sortKey);
      searchParams.set('direction', desc ? 'desc' : 'asc');
    }
    if (debouncedSearchTerm) {
      searchParams.set('search', debouncedSearchTerm);
    }
    return getTableData(searchParams);
  }, [debouncedSearchTerm, getTableData, pagination.pageIndex, pagination.pageSize, sorting, urlParams]);

  const { refetch, data, isError, isFetching, isLoading } = useQuery({
    queryKey: [tableKey, undefined, debouncedSearchTerm, pagination.pageIndex, pagination.pageSize, sorting],
    queryFn,
    placeholderData: (prev) => prev,
  });

  const table = useMaterialReactTable({
    columns: columnDefinitions,
    /**
     * Replace nulls with empty strings so sorting toggles work
     * @see https://github.com/TanStack/table/issues/5147
     */
    data: data?.data.map((row) => Object.keys(row).reduce((newRow, key) => ({ ...newRow, [key]: row[key] ?? '' }), {} as T)) ?? [],
    enableColumnFilters: false,
    manualPagination: true,
    muiSearchTextFieldProps: {
      variant: 'outlined',
      size: 'small',
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 100],
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,

    muiTableHeadRowProps: {
      sx: { backgroundColor: `${theme.palette.grey[100]}` },
    },
    muiTableHeadCellProps: {
      sx: {
        px: 2,
        py: 2,
        fontWeight: 500,
      },
    },
    muiTableBodyRowProps: {
      sx: {
        minHeight: 65,
        // Remove border on last row of table
        '&:last-child .MuiTableCell-root': {
          borderBottom: 0,
        },
        // Table cells hover background color
        '&:hover .MuiTableCell-root::after': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        p: 2,
        backgroundColor: 'white',
        borderBottomColor: theme.palette.grey[200],
      },
    },
    muiBottomToolbarProps: {
      sx: {
        minHeight: '5rem',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'white',
        '& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiTypography-root': { fontSize: '12px' },
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A',
        borderRadius: theme.spacing(1),
      },
    },
    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
        margin: 0,
      },
    },
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 400px)' } },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', // change header text
        size: 10, // make actions column wider
        maxSize: 10,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    },
    layoutMode: 'grid',
    enableRowActions: true,
    renderRowActionMenuItems: rowActions,
    positionActionsColumn: 'last',
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualSorting: true,
    rowCount: data?.meta?.total ?? 0,
    enableTopToolbar: false,
    enableColumnActions: false,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      columnPinning: {
        left: columnDefinitions.filter((col) => col.enablePinning).map((col) => col.accessorKey || col.header),
        right: columnDefinitions.filter((col) => col.accessorKey === 'action').map((col) => col.accessorKey!),
      },
    },
  });

  return { table, refetch };
};
