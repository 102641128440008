export const ACTION_WITHDRAW = 'withdraw';
export const ACTION_NEXT = 'next';
export const ACTION_SKIP_TO_STEP = 'skip-to-step';
export const ACTION_EXPORT = 'export';
export const ACTION_SAVE = 'save';
export const ACTION_DECLINE = 'decline';
export const ACTION_SHARE_INVOICE = 'share-invoice';
export const ACTION_LINK = 'link';
export const ACTION_REENROL = 're-enrol';
export const ACTION_SAVE_WITH_COMPLETION = 'save-with-completion';
export const ACTION_TICKETDIALOG = 'ticketdialog';
export const ACTION_ENTRYPOINT = 'entrypoint';

// Appears on the left (relevant for both step and workflow actions)
export const POS_LEFT = 'left';
// Appears on the right (relevant for both step and workflow actions)
export const POS_RIGHT = 'right';
// Appears in the pipeline, on the previous stage (relevant for step actions)
export const POS_PREVIOUS_STAGE = 'previous-stage';
// Appears in the pipeline (relevant for step actions)
export const POS_PIPELINE = 'pipeline';
// Appears to the left of the step, displays a list of steps within the stage
export const POS_STEPLIST = 'step-list';
// Appears on /pipeline, replaces the "Add application" button if present
export const POS_APPLICATION_HEADER = 'application-header';
// Appears inside the step, as a field
export const POST_FIELDINSTEP = 'field-in-step';

// Appears in the respective tables
export const POS_TABLE_PARTICIPANTS = 'table-participants';
export const POS_TABLE_POSITIONS = 'table-positions';
export const POS_TABLE_HOST_ORGS = 'table-host-orgs';
export const POS_TABLE_PARTNERS = 'table-partners';
