import Loader from 'components/Loader';
import StageScroller from 'views/Steps/StageScroller';
import ApplicantPipelineStageColumn from 'applicants/ApplicantPipelineStageColumn';
import ApplicantPipelineCompleteApplications from 'components/ProgressColumn/ApplicantPipelineCompleteApplications';
import EmptyState from 'components/Profile/EmptyState';
import { APPLICATION_STATUS_ENROLLED } from 'app/constants/ApplicationStatuses';
import { useApplicant } from 'contexts/ApplicantContext';
import { useParams } from 'react-router-dom';
import { SchoolWorkflowRouteParams } from 'app/routes/SchoolWorkflowRoutes';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Application } from '../types/Application';

interface PipelineStage {
  applications: Application[];
  id: string;
  name: string;
  name_translation_key: string;
  total_applications_count: number;
}

export const FilteredApplicantPipeline = ({ filters, applicationData, loading }) => {
  const {
    state: { applicant },
  } = useApplicant();
  const { applicant: applicantId } = useParams() as SchoolWorkflowRouteParams;

  const renderApplications = () => {
    const stages: PipelineStage[] = applicationData?.data || [];

    if (filters.status && filters.status === APPLICATION_STATUS_ENROLLED) {
      return <ApplicantPipelineCompleteApplications stages={stages} />;
    }

    const allApplications = stages.flatMap((stage) => stage.applications);

    if (!allApplications.length) {
      return <EmptyState nounSingular="application" nounPlural="applications" />;
    }

    return (
      <StageScroller>
        {stages.map((stage) => (
          <ApplicantPipelineStageColumn
            key={stage.id}
            id={stage.id}
            name={stage.name}
            nameTranslationKey={stage.name_translation_key}
            totalApplications={stage.total_applications_count}
            applications={stage.applications || []}
          />
        ))}
      </StageScroller>
    );
  };

  return (
    <Grid container justifyContent="center" pt={2}>
      <Grid lg={12} xl={10}>
        {loading || (!applicantId && !applicant) ? <Loader fullPage /> : renderApplications()}
      </Grid>
    </Grid>
  );
};
