import { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { MenuLink } from 'components/MenuLink/MenuLink';
import { RemindersDialog } from 'enquiries/reminders/RemindersDialog';
import { MobileMenu } from 'components/MobileMenu/MobileMenu';
import HasAccess from 'permissions/HasAccess';
import { ProductsAccess } from 'permissions/ProductsAccess';
import { mainNav } from 'config/routes';
import { reminders } from 'enquiries/enquiriesSelector';
import { Badge, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import { slugify } from 'utils/slugify';
import { ProfileMenu } from 'components/Menu/ProfileMenu';
import { LocaleMenu } from 'components/Menu/LocaleMenu';
import { PERMISSION_SELECT_LANGUAGE } from 'permissions/constants/Roles';
import { isMenuItemActive } from '../../utils/isMenuItemActive';

const MainNavItem = ({ nav, index, slug }) => {
  const isRoot = nav.url[0] === '/';
  let url = nav.url;

  if (!isRoot && nav.requiresSchool) {
    url = `/${slug}/${nav.url}`;
  }

  url = nav.external ? nav.url : url;

  return (
    <HasAccess key={`item-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
      <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
        <MenuLink to={url} navChildren={nav.children} label={nav.title} external={nav.external} active={isMenuItemActive(nav.url)} titleIcon={nav.titleIcon} />
      </ProductsAccess>
    </HasAccess>
  );
};

const MainNavMenu = ({ items, slug, schoolColor, handleSubnavToggle, subnavIndex }) => {
  const theme = useTheme();
  const hideMainMenu = useMediaQuery(theme.breakpoints.down('lg'));

  if (hideMainMenu) return <></>;

  return items.map((nav, index: number) => {
    if (nav.title === 'Profile') {
      return (
        <HasAccess key={`item-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
          <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
            <ProfileMenu slug={slug} />
          </ProductsAccess>
        </HasAccess>
      );
    }

    return (
      <MainNavItem
        key={slugify(nav.title)}
        {...{
          nav,
          index,
          slug,
          schoolColor,
          handleSubnavToggle,
          subnavIndex,
        }}
      />
    );
  });
};

export const Menu = () => {
  const [subnavIndex, setSubnavIndex] = useState<number | null>(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const slug = useAppSelector((state) => state.school.slug);
  const schoolColor = useAppSelector((state) => state.school.color);
  const totalReminders = useAppSelector((state) => reminders(state, {}).length);

  const handleSubnavToggle = (index: number) => {
    setSubnavIndex(index !== subnavIndex ? index : null);
  };

  const handleNotificationToggle = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const mainNavItems = mainNav.filter((nav) => {
    if (nav.hide) return false;

    const isRoot = nav.url[0] === '/';

    if (!isRoot) {
      if (nav.requiresSchool && !slug && nav.title !== 'Profile' && !nav.external) {
        return false;
      }
    }

    return true;
  });
  const leftMainNavItems = mainNavItems.filter((nav) => !nav.alignRight);
  const rightMainNavItems = mainNavItems.filter((nav) => nav.alignRight);

  const renderNotificationBadge = () => {
    if (typeof window === 'undefined' || totalReminders <= 1) {
      return <></>;
    }

    return [
      <Tooltip key={0} title="Notifications">
        <IconButton onClick={handleNotificationToggle}>
          <Badge badgeContent={totalReminders}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>,
      <RemindersDialog key={1} active={isNotificationsOpen} onToggle={handleNotificationToggle} />,
    ];
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
      <Stack flex={1} direction="row" alignItems="center" justifyContent="flex-start" spacing={4} mr={3}>
        <MainNavMenu items={leftMainNavItems} {...{ slug, schoolColor, handleSubnavToggle, subnavIndex }} />
      </Stack>
      <Stack flex={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
        {renderNotificationBadge()}
        <MainNavMenu items={rightMainNavItems} {...{ slug, schoolColor, handleSubnavToggle, subnavIndex }} />
        <HasAccess for="permission" name={[PERMISSION_SELECT_LANGUAGE]}>
          <LocaleMenu />
        </HasAccess>
      </Stack>
      <MobileMenu
        mainNav={mainNav}
        localeChooser={
          <HasAccess for="permission" name={[PERMISSION_SELECT_LANGUAGE]}>
            <LocaleMenu />
          </HasAccess>
        }
      />
    </Stack>
  );
};
