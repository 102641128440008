import { useAppSelector } from 'app/hooks';
import { useLocale } from 'contexts/LocaleContext';
import currency from 'currency/currency';

export const Currency = ({ amount }) => {
  const { locale } = useLocale();
  const currencyCode = useAppSelector((state) => state.school.currency_code);

  return <>{currency(amount, currencyCode, locale)}</>;
};
