import { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import fileDownload from 'js-file-download';
import { intlFormat, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { fileSize } from 'utils/fileSize';
import { ReactComponent as PDFIcon } from 'components/DocStack/img/icon-pdf.svg';
import { ReactComponent as ImageIcon } from 'components/DocStack/img/icon-image.svg';
import { ReactComponent as MoreIcon } from 'images/icons/Material/more.svg';
import { ReactComponent as DownloadIcon } from 'images/icons/Material/download.svg';
import { ReactComponent as EditIcon } from 'images/icons/Material/edit.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/Material/delete.svg';
import { trackDocumentDelete, trackDocumentDownload } from 'utils/tracking';
import { ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_STAFF } from 'permissions/constants/Roles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import { usePermissions } from 'contexts/PermissionContext';
import { getConfig } from '../utils/config';
import { useDelete } from '../hooks/useDelete';
import { usePut } from '../hooks/usePut';
import { useNotificationMessages } from '../hooks/useNotificationMessages';
import { getErrorMessage } from '../utils/errors';
import { ResponseEnvelope } from '../types/ResponseEnvelope';
import { AppContext } from '../app/AppContext';
import { useLocale } from '../contexts/LocaleContext';

const Icon = styled.div`
  margin-right: 1rem;
`;

const Preview = styled.figure`
  min-width: 8rem;
  width: 8rem;
  height: 4.5rem;
  max-height: 4.5rem;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
`;

const FileLink = styled.span`
  display: flex;
  align-items: center;
  color: #474747;
  font-weight: normal;
  letter-spacing: 0;
  white-space: normal;
  word-break: break-word;
`;

const DocumentsTableRow = ({ index, file, onRemove, onEdit }) => {
  const { hasRole } = usePermissions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadingFiles, setDownloadingFiles] = useState([]);
  const { apiUrl } = getConfig();
  const { localeCode } = useLocale();
  const appContext = useContext(AppContext);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const withClose = (fn, ...args) => {
    handleClose();
    fn(...args);
  };

  const renderIcon = (file) => {
    if (!file) {
      return null;
    }

    switch (file.type) {
      case 'image/jpeg':
        if (file.withPreview) {
          return (
            <Preview>
              <img src={`/${file.preview}`} alt={file.name} />
            </Preview>
          );
        }

        return <ImageIcon height="20" width="20" />;
      case 'image/png':
        if (file.withPreview) {
          return (
            <Preview>
              <img src={`/${file.preview}`} alt={file.name} />
            </Preview>
          );
        }

        return <ImageIcon height="20" width="20" />;
      default:
        return <PDFIcon width="20" height="20" />;
    }
  };

  const handleRemove = (fileId) => {
    onRemove(fileId);
  };

  const setDownloadingState = (index, value) => {
    const files = downloadingFiles.slice();
    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
    files[index] = value;
    setDownloadingFiles(files);
  };

  const handleDownload = (filePreview, fileName, index) => {
    setDownloadingState(index, true);

    trackDocumentDownload(filePreview);

    const endpoint = `${apiUrl}/schools/${filePreview}`;
    const params = {
      method: 'get',
      headers: appContext.headers,
      responseType: 'blob',
    };

    fetch(endpoint, params)
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(new Error(response.statusText));
        }

        return Promise.resolve(response);
      })
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, fileName);

        setDownloadingState(index, false);
      })
      .catch((error) => {
        appContext.operations.onError(error);

        setDownloadingState(index, false);
      });
  };

  return (
    <TableRow>
      <TableCell>
        {downloadingFiles[index] ? (
          <FileLink>
            <Icon>
              <DownloadIcon height="20" width="20" />
            </Icon>{' '}
            {file.filename}
          </FileLink>
        ) : (
          <FileLink onClick={() => handleDownload(file.preview, file.filename, index)}>
            <Icon>{renderIcon(file)}</Icon> {file.filename}
          </FileLink>
        )}
      </TableCell>
      <TableCell>{file.tag.replace('-', ' ')}</TableCell>
      <TableCell>{fileSize(file.size)}</TableCell>
      <TableCell>
        {file.created_at &&
          intlFormat(
            parseISO(file.created_at),
            { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
            { locale: localeCode },
          )}
      </TableCell>
      <TableCell>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem value="complete" disabled={downloadingFiles[index]} onClick={() => withClose(() => handleDownload(file.preview, file.filename, index))}>
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>
          {hasRole([ROLE_TYPE_INSTITUTION_ADMIN, ROLE_TYPE_INSTITUTION_STAFF]) && (
            <MenuItem value="edit" onClick={() => withClose(() => onEdit(file))}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
          {file.tag === 'school-upload' && (
            <MenuItem value="delete" onClick={() => withClose(() => handleRemove(file.id))}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};

const DocumentsTable = ({ files, onUpdate }) => {
  const [fileId, setFileId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editFile, setEditFile] = useState({ id: '', filename: '' });
  const { slug: schoolSlug, application: applicationId } = useParams() as ApplicantRouteParams;
  const { apiUrl } = getConfig();
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();
  const [loadingDelete, deleteFile] = useDelete<ResponseEnvelope<any>>(`/schools/${schoolSlug}/applications/${applicationId}/files/${fileId}`);
  const [loadingPost, putFile] = usePut<ResponseEnvelope<any>>(`${apiUrl}/schools/${schoolSlug}/applications/${applicationId}/files/${editFile.id}`);

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>File name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>File size</TableCell>
          <TableCell>Date added</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const handleConfirmFileRemove = () => {
    deleteFile().then(() => {
      trackDocumentDelete();
      setShowConfirm(false);
      setFileId(null);
    });
  };

  const showConfirmFileRemove = (id) => {
    setShowConfirm(true);
    setFileId(id);
  };

  const onEdit = (file) => {
    setShowEditDialog(true);
    setEditFile({ id: file.id, filename: file.filename });
  };

  const handleClose = () => {
    setShowConfirm(false);
    setFileId(null);
  };

  const saveDocuments = useCallback(async () => {
    try {
      const response = await putFile({
        filename: editFile.filename,
      });
      showSuccessMessage(response.message);
      onUpdate();
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    } finally {
      setShowEditDialog(false);
    }
  }, [editFile.filename, onUpdate, putFile, showErrorMessage, showSuccessMessage]);

  if (!files.length) {
    return <div>No files</div>;
  }

  return (
    <>
      <Table>
        {renderTableHeader()}
        <TableBody>
          {files.map((file, index) => (
            <DocumentsTableRow key={`row-${index}`} index={index} file={file} onRemove={() => showConfirmFileRemove(file.id)} onEdit={onEdit} />
          ))}
        </TableBody>
      </Table>
      <Dialog open={showEditDialog} onClose={() => setShowEditDialog(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Typography variant="h4" component="h2">
              Rename File
            </Typography>
            <IconButton
              aria-label="close"
              title="Close"
              onClick={() => setShowEditDialog(false)}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth required>
            <FormLabel id="filenameLabel">File name</FormLabel>
            <TextField
              size="small"
              aria-labelledby="filenameLabel"
              value={editFile.filename}
              required
              onChange={(e) => {
                setEditFile({
                  id: editFile.id,
                  filename: e.target.value,
                });
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditDialog(false)}>Close</Button>
          <LoadingButton variant="contained" disabled={editFile.filename.trim() === ''} loading={loadingPost} onClick={() => saveDocuments()}>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={showConfirm} onClose={handleClose}>
        <DialogTitle variant="h4" component="h2">
          Are you sure you want to remove this file?
        </DialogTitle>
        <DialogActions>
          <LoadingButton variant="contained" loading={loadingDelete} onClick={handleConfirmFileRemove}>
            Yes
          </LoadingButton>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentsTable;
