import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Stack, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { slugify } from 'utils/slugify';

interface CheckboxsetFieldProps {
  label: string;
  disabled: boolean;
  value: string[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  options: { label: string; value: string }[];
}

export const CheckboxsetField = ({ label, disabled, value, onChange, error, name, readOnly, options }: CheckboxsetFieldProps) => {
  const handleChange = (value, optionValue, onChange) => {
    const fieldValues = value.slice() || [];

    if (fieldValues.indexOf(optionValue) !== -1) {
      fieldValues.splice(fieldValues.indexOf(optionValue), 1);
    } else {
      fieldValues.push(optionValue);
    }

    onChange(fieldValues);
  };

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="checkbox-set" sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}>
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <FormGroup>
          <Stack direction="row" flexWrap="wrap" columnGap={3} rowGap={2}>
            {options.map(({ value: optionValue, label: optionLabel }) => {
              const valueSlug = slugify(optionValue);
              return (
                <FormControlLabel
                  key={`${name}-checkbox-${valueSlug}`}
                  control={
                    <Checkbox
                      data-cy={`checkbox-set-option-${valueSlug}`}
                      disabled={disabled}
                      value={optionValue}
                      checked={value.includes(optionValue)}
                      onChange={() => handleChange(value, optionValue, onChange)}
                    />
                  }
                  label={optionLabel || optionValue}
                />
              );
            })}
            <Box flexGrow="1" />
          </Stack>
        </FormGroup>
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default CheckboxsetField;
