import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import Lang from 'lang.js';
import translations from 'lang/lang.json';
import { useAppDispatch } from '../app/hooks';
import { setLocale as setLocaleRedux } from 'app/actions/userActions';
import { enNZ, Locale } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface LocaleContextData {
  locale: string;
  localeCode: string;
  label: string;
  image: string;
  setLocaleValues: (newLocale: string, newLabel: string, newImage: string) => void;
  getTranslationForKey: (key: string, params?: Record<string, string>) => string | null;
}

const LocaleContext = createContext<LocaleContextData | undefined>(undefined);
const lang = new Lang({});
// @ts-expect-error TS(2345): Argument of type '{ "en.Study": { "location ": str... Remove this comment to see the full error message
lang.setMessages(translations);

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const [dateFnsLocale, setDateFnsLocale] = useState<Locale>(enNZ);
  const [locale, setLocale] = useState<string>('en');
  const [label, setLabel] = useState<string>('EN');
  const [image, setImage] = useState<string>('gb');
  const dispatch = useAppDispatch();

  const setLocaleValues = (newLocale: string, newLabel: string, newImage: string) => {
    lang.setLocale(locale);
    dispatch(setLocaleRedux(newLocale, newLabel, newImage));
    setLocale(newLocale);
    setLabel(newLabel);
    setImage(newImage);
  };

  const getTranslationForKey = useCallback(
    (translationKey: string, params = {}) => (lang.has(translationKey) ? lang.get(translationKey, params, locale) : null),
    [locale],
  );

  const getDateFnsLocale = useCallback(async (): Promise<Locale> => {
    const localeCode = navigator.language || 'en-NZ';
    /* webpackChunkName: i18n-locale- */
    const loader = (await import(`../../node_modules/date-fns/locale/${localeCode}.js`)).default;
    return loader[localeCode.replace('-', '')];
  }, []);

  useEffect(() => {
    getDateFnsLocale().then((dateFnsLocale) => {
      console.info(`Setting locale to ${dateFnsLocale.code}`);
      setDateFnsLocale(dateFnsLocale);
      setDefaultOptions({
        locale: dateFnsLocale,
      });
    });
  }, [getDateFnsLocale]);

  return (
    <LocaleContext.Provider value={{ locale, label, image, setLocaleValues, getTranslationForKey, localeCode: dateFnsLocale.code }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
        {children}
      </LocalizationProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return context;
};
