import defaultRequest from 'rest/interceptor/defaultRequest';
import template from 'rest/interceptor/template';
import client from 'app/api/client';
import formDataInterceptor from 'app/api/common/formDataInterceptor';
import { getConfig } from '../utils/config';

const { apiUrl } = getConfig();

const paymentsAPI = client
  .wrap(formDataInterceptor)
  .wrap(template)
  .wrap(defaultRequest, {
    path: `${apiUrl}/schools/{slug}/payments{/id}{/action}`,
  });

const api = {
  PUT: paymentsAPI.wrap(defaultRequest, { method: 'PUT' }),
  DELETE: paymentsAPI.wrap(defaultRequest, { method: 'DELETE' }),
};

export default api;
