import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { ROLE_TYPE_AGENT, ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF } from 'permissions/constants/Roles';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from 'components/Loader';
import { getConfig } from 'utils/config';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { AuthUser } from 'utils/user';
import { usePermissions } from '../contexts/PermissionContext';
import { getLandingPage } from '../utils/getLandingPage';

export const PublicOnlyRoute = ({ children }) => {
  const location = useLocation();
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const { marketplaceUrl } = getConfig();
  const { isAuthenticated, isLoading, user } = useAuth0<AuthUser>();
  const { hasGroup, hasRole } = usePermissions();

  if (isLoading) {
    return <Loader fullPage />;
  }

  if (!isAuthenticated || !user) {
    return children;
  }

  const state = {
    from: location,
  };

  const redirectIfLoggedIn = getLandingPage(user, hasGroup, hasRole, schoolSlug);

  // Agents logged in go to Marketplace
  const rolesToRedirectToMarketplace = [ROLE_TYPE_AGENT, ROLE_TYPE_INSTITUTION_MARKETING_ONLY_STAFF];
  if (user.userData.roles.find((r) => rolesToRedirectToMarketplace.includes(r))) {
    window.location.href = marketplaceUrl;
    return <></>;
  }

  return (
    <>
      {location.search && <Navigate state={state} to={location.search.replace('?back=', '')} />}
      {isAuthenticated && <Navigate state={state} to={redirectIfLoggedIn} />}
    </>
  );
};
