import { FormBuilderAction } from 'components/FormBuilder/types/FormBuilderAction';
import { useSubmitContext } from '../SubmitContext';
import { LoadingButton } from '@mui/lab';
import { Box, ButtonProps, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';

interface SaveWithCompletionProps {
  action: FormBuilderAction;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const SaveWithCompletion = ({ action, color, variant }: SaveWithCompletionProps) => {
  const percentage = action.progressIndicator?.percentage ?? 0;
  const { triggerSubmit, isFormLoading } = useSubmitContext();
  const handleClick = () => {
    triggerSubmit();
  };

  const progressItems = action.progressIndicator?.steps ?? [];

  const progressContent = (
    <Stack gap={1} sx={{ '& .MuiTypography-root': { fontSize: 12 } }}>
      <Typography variant="body2" mb={2} sx={{ color: (theme) => theme.palette.grey[600] }}>
        Progress:
      </Typography>
      {progressItems.map((item) => (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">{item.name}</Typography>
          <Typography variant="body2">
            {item.completed}/{item.required}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Stack direction="row" gap={1} sx={{ cursor: 'pointer' }}>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              minWidth: 250,
              padding: 2,
              border: (theme) => `1px solid ${theme.palette.grey[300]}`,
              borderRadius: '9px',
              bgcolor: 'white',
              '& .MuiTooltip-arrow': {
                color: 'white',
                '&:before': {
                  border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                },
              },
            },
          },
        }}
        title={progressContent}
        arrow
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{action.frontendConfig?.label}:</Typography>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={percentage} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" color="text.primary">{`${Math.round(percentage)}%`}</Typography>
            </Box>
          </Box>
        </Stack>
      </Tooltip>
      <LoadingButton variant={variant} color={color} loading={isFormLoading} disabled={action.disabled} onClick={handleClick}>
        {action.label}
      </LoadingButton>
    </Stack>
  );
};
