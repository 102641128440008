export enum FormBuilderFieldType {
  Checkbox = 'checkbox',
  Text = 'text',
  Email = 'email',
  PhoneNumber = 'tel',
  Country = 'country',
  Time = 'time',
  TextArea = 'textarea',
  Separator = 'separator',
  Password = 'password',
  Timezone = 'timezone',
  MonthYear = 'monthyear',
  StartAndEndDate = 'startandenddate',
  StepList = 'steplist',
  ApplicantDetails = 'applicantdetails',
  CountryAndTimezone = 'countryandtimezone',
  CustomFieldsPlaceholder = 'customfieldsplaceholder',
  ApiJson = 'apijson',
  ActionSlot = 'actionslot',
  Content = 'content',
  RadioGroup = 'radiogroup',
  DropdownExternal = 'externaldropdown',
  MultiLevelMultiSelect = 'multilevelmultiselect',
  DropdownMegaselect = 'megaselectionmenu',
  Dropdown = 'dropdown',
  CheckboxSet = 'checkboxset',
  AutoComplete = 'autocomplete',
  ConditionsCheckboxSet = 'conditionscheckboxset',
  Number = 'number',
  File = 'file',
  DatePicker = 'datepicker',
  DocumentList = 'documents',
  LocalTime = 'localtime',
  SelectLocalTime = 'selectlocaltime',
  Payment = 'payment',
  StandardPhoneNumber = 'standardphonenumber',
  AccountSummary = 'accountsummary',
  Invoice = 'invoice',
  PaymentOptions = 'paymentoptions',
  StepSummary = 'stepsummary',
  TemplateDisplay = 'templatedisplay',
  CreateInvoice = 'createinvoice',
  SchoolSubjectsSelector = 'schoolsubjectsselector',
  PartyAssignment = 'partyassignment',
  Tabs = 'tabs',
  InfoBox = 'infobox',
  ChipsBox = 'chipsbox',
  ReviewStep = 'reviewstep',
  PlacesAutocomplete = 'placesautocomplete',
  ToggleButton = 'togglebutton',
  ZohoSignFrame = 'zohosignframe',
}
