import { Reminders } from 'enquiries/reminders/Reminders';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

export const RemindersDialog = ({ active, onToggle }) => {
  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogContent>
        <Reminders withTitle alt active={active} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onToggle}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
