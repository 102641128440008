import { FormBuilderAction } from '../types/FormBuilderAction';
import { ApiFormBuilderAction } from '../types/api/ApiFormBuilderAction';

export const convertApiActionsToBuilderActions = (newActions: ApiFormBuilderAction[]): FormBuilderAction[] => {
  return newActions.map((action) => {
    return {
      id: action.id,
      type: action.action_type,
      label: action.button_name,
      description: action.description,
      name: action.name,
      position: action.position,
      frontendConfig: action.frontend_config,
      progressIndicator: action.progress_indicator,
      userType: action.user_type,
      style: action.style,
      overrideDestinationStepId: action.override_destination_step_id,
      disabled: !!action.disabled,
    };
  });
};
