import { applyMiddleware, createStore, compose } from 'redux';
import { thunk } from 'redux-thunk';
import promise from 'redux-promise';
import LogRocket from 'logrocket';
import { rootReducer, sharedRootReducer } from 'app/reducers';
import { getConfig } from 'utils/config';

const isServer = typeof window === 'undefined';
const { environment } = getConfig();

const rootReducerInst = rootReducer();
const sharedRootReducerInst = sharedRootReducer();

let logger;
let sharedAppLogger;

const identity = (x) => x;

if (isServer || environment === 'production') {
  logger = identity;
  sharedAppLogger = identity;
} else {
  // @ts-expect-error TS(2339): Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
  logger = window.__REDUX_DEVTOOLS_EXTENSION__
    ? // @ts-expect-error TS(2339): Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
      window.__REDUX_DEVTOOLS_EXTENSION__({
        name: 'Enroller',
      })
    : identity;
  // @ts-expect-error TS(2339): Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
  sharedAppLogger = window.__REDUX_DEVTOOLS_EXTENSION__
    ? // @ts-expect-error TS(2339): Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
      window.__REDUX_DEVTOOLS_EXTENSION__({
        name: 'Shared Enroller',
      })
    : identity;
}

if (logger === identity && (environment === 'development' || environment === 'local')) {
  console.info('Download the Redux dev tools', 'https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd');
}

const middleware = applyMiddleware(
  thunk,
  promise,
  LogRocket.reduxMiddleware({
    stateSanitizer: function (state) {
      return {
        ...state,
        nopersist: undefined,
      };
    },
  }),
);

export const store = createStore(rootReducerInst, {}, compose(middleware, logger));

export const sharedAppStore = createStore(sharedRootReducerInst, {}, compose(middleware, sharedAppLogger));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
