import { useContext, useState } from 'react';
import fileDownload from 'js-file-download';
import { formatDistanceToNow } from 'date-fns';
import { AppContext } from 'app/AppContext';
import { fileSize } from 'utils/fileSize';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { getConfig } from '../../utils/config';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GradientIcon } from 'components/GradientIcon/GradientIcon';

interface DocStackFileProps {
  preview: string;
  name: string;
  mime: string;
  size: number;
  createdDate: string;
  directLink?: boolean;
  compact?: boolean;
  disabled?: boolean;
  withBackground?: boolean;
  withPreview?: boolean;
  withLinks?: boolean;
}

export const File = ({
  preview,
  name,
  mime,
  size,
  createdDate,
  directLink = false,
  compact = false,
  disabled = false,
  withBackground = false,
  withPreview = false,
  withLinks = false,
}: DocStackFileProps) => {
  const appContext = useContext(AppContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const { apiUrl } = getConfig();
  const theme = useTheme();

  const downloadFile = async (appContext) => {
    const endpoint = `${apiUrl}/schools/${preview}`;
    const params = {
      method: 'get',
      headers: appContext.headers,
      responseType: 'blob',
    };

    setIsDownloading(true);

    fetch(endpoint, params)
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(new Error(response.statusText || 'Error downloading file'));
        }
        return Promise.resolve(response);
      })
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, name);
        setIsDownloading(false);
      })
      .catch((error) => {
        appContext.operations.onError(error);
        setIsDownloading(false);
      });
  };

  const isImage = ['image/jpeg', 'image/jpg', 'image/png'].includes(mime);
  const renderPreview = isImage && withPreview;
  const GradientIconComponent = isImage ? PhotoOutlinedIcon : mime === 'text/calendar' ? EventOutlinedIcon : DescriptionOutlinedIcon;

  return (
    <Stack
      border={`1px solid ${theme.palette.primary.main}`}
      borderRadius={theme.spacing(1)}
      component="li"
      m={1}
      width={{
        xs: '100%',
        lg: compact ? '32%' : '100%',
      }}
      maxWidth={{
        lg: compact ? '32%' : '100%',
      }}
      minHeight="9rem"
      justifyContent={compact ? 'space-between' : 'flex-start'}
      bgcolor={withBackground ? 'background.paper' : 'transparent'}
      boxShadow={withBackground ? '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)' : 'none'}
    >
      <a
        href={directLink ? preview : '#'}
        target={directLink ? '_blank' : '_self'}
        rel="noopener noreferrer"
        title={name}
        onClick={() => {
          if (directLink) return false;
          downloadFile(appContext);
        }}
        style={{
          padding: '16px',
          display: 'flex',
          flex: 1,
          cursor: disabled || isDownloading ? 'wait' : 'pointer',
        }}
      >
        {renderPreview && (
          <Box component="figure" sx={{ width: '8rem', minWidth: '8rem', height: '4.5rem', maxHeight: '4.5rem', overflow: 'hidden' }}>
            <img src={`/${preview}`} alt={name} style={{ width: 'auto', height: '100%' }} />
          </Box>
        )}
        {!renderPreview && <GradientIcon color="primary" IconComponent={GradientIconComponent} />}
        <Stack ml={2} width={compact ? 'auto' : '100%'}>
          <Box>
            <Typography>{name}</Typography>
            <Stack>
              <Typography variant="subtitle2" fontSize="small" color={grey[500]}>
                {fileSize(size, 2)}
              </Typography>
              <Typography variant="subtitle2" fontSize="small" component="time" dateTime={createdDate} color={grey[500]}>
                {createdDate && formatDistanceToNow(new Date(createdDate), { addSuffix: true })}
              </Typography>
            </Stack>
          </Box>
          <Stack alignItems="flex-start" flexWrap="wrap">
            {withLinks && (
              <Button variant="text" size="small" color="primary" sx={{ fontSize: 'small', textTransform: 'uppercase', p: 0 }}>
                {isDownloading ? 'Downloading...' : 'Download'}
              </Button>
            )}
          </Stack>
        </Stack>
      </a>
    </Stack>
  );
};

export default File;
