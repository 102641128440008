import { isEmpty, isDate } from 'lodash';
import { addDays, isAfter, isBefore, parseISO, subDays } from 'date-fns';

export const equal = (referenceValue, compareTo) => {
  return referenceValue === compareTo;
};

export const notequal = (referenceValue, compareTo) => {
  return !equal(referenceValue, compareTo);
};

export const greaterthan = (referenceValue, compareTo) => {
  return referenceValue !== '' && parseInt(referenceValue, 10) > parseInt(compareTo, 10);
};

export const lessthan = (referenceValue, compareTo) => {
  return referenceValue !== '' && parseInt(referenceValue, 10) < parseInt(compareTo, 10);
};

export const contains = (referenceValue, compareTo) => {
  return referenceValue ? referenceValue.includes(compareTo) : false;
};

export const doesnotcontain = (referenceValue, compareTo) => {
  return !contains(referenceValue, compareTo);
};

export const empty = (referenceValue) => {
  if (referenceValue === null || referenceValue === '') {
    return true;
  } else {
    switch (typeof referenceValue) {
      case 'undefined':
        return true;
      case 'boolean':
        return !referenceValue;
      case 'number':
        return false;
    }

    if (Object.prototype.hasOwnProperty.call(referenceValue, 'length')) {
      return !referenceValue.length;
    } else if (isDate(referenceValue)) {
      return false;
    }

    return isEmpty(referenceValue);
  }
};

export const notempty = (referenceValue) => {
  return !empty(referenceValue);
};

export const checked = (referenceValue) => {
  return !!referenceValue;
};

export const notchecked = (referenceValue) => {
  return !checked(referenceValue);
};

export const daysbefore = (referenceValue: Date | string | null, days: number) => {
  if (referenceValue === null) return false;
  const referenceDate = typeof referenceValue === 'string' ? parseISO(referenceValue) : referenceValue;
  return isBefore(referenceDate, subDays(new Date(), days));
};

export const daysafter = (referenceValue: Date | string | null, days: number) => {
  if (referenceValue === null) return false;
  const referenceDate = typeof referenceValue === 'string' ? parseISO(referenceValue) : referenceValue;
  return isAfter(referenceDate, addDays(new Date(), days));
};
