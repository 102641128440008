import React, { createContext, useContext, ReactNode, useState } from 'react';

type SubmitContextType = {
  setSubmit: (submit: () => void) => void;
  setIsFormLoading: (boolean) => void;
  isFormLoading: boolean;
  triggerSubmit: () => void;
};

// Used to be able to submit the form within FormBuilder (inside WorkflowContent) from the WorkflowHeader component

const SubmitContext = createContext<SubmitContextType | undefined>(undefined);

export const useSubmitContext = (): SubmitContextType => {
  const context = useContext(SubmitContext);
  if (context === undefined) {
    throw new Error('useSubmitContext must be used within a SubmitProvider');
  }
  return context;
};

interface SubmitProviderProps {
  children: ReactNode;
}

export const SubmitProvider: React.FC<SubmitProviderProps> = ({ children }) => {
  const submitRef = React.useRef<(() => void) | null>(null);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const setSubmit = (submit: () => void) => {
    submitRef.current = submit;
  };

  const triggerSubmit = () => {
    if (submitRef.current) {
      submitRef.current();
    }
  };

  return <SubmitContext.Provider value={{ setSubmit, triggerSubmit, isFormLoading, setIsFormLoading }}>{children}</SubmitContext.Provider>;
};
