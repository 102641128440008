import { ApplicationProvider, useApplication } from 'contexts/ApplicationContext';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { WorkflowActionList } from './actions/WorkflowActionList';
import Loader from '../Loader';
import { ACTION_LINK, POS_STEPLIST } from '../../workflow/actions/constants/Actions';
import { getStepActionsForStage } from './utils/getStepActionsForStage';
import { FormBuilderAction } from '../FormBuilder/types/FormBuilderAction';
import { useParams } from 'react-router-dom';
import { WorkflowStep } from './WorkflowStep';
import { WorkflowStepRouteParams } from './WorkflowApplicationLayout';
import makeLinkToStep from '../../workflow/utils/makeLinkToStep';
import content from '../../utils/content';
import { StageMessage, WorkflowStage, WorkflowStep as WorkflowStepType } from './types/Workflow';
import { useEffect, useMemo } from 'react';
import { useSubHeader } from '../AppLayout/AppLayout';
import { WorkflowHeader } from './WorkflowHeader';

interface WorkflowStepWithStageSlug extends WorkflowStepType {
  stageSlug: string;
}

export const WorkflowContent = () => {
  const { slug: schoolSlug, applicant: applicantId, stage: stageSlug, step: stepSlug } = useParams() as WorkflowStepRouteParams;
  const {
    state: { application },
  } = useApplication();
  const theme = useTheme();
  const { setSubHeader } = useSubHeader();
  const isMediumOrAbove = useMediaQuery(theme.breakpoints.up('md'));
  const currentStage = application?.workflow.stages.find((stage) => stage.slug === stageSlug) || null;
  const currentStageMessages: StageMessage[] = application?.workflow.stages.find((stage) => stage.slug === stageSlug)?.messages || [];
  const stepsMap = useMemo(() => {
    const map = new Map<string, WorkflowStepWithStageSlug>();
    application?.workflow.stages.forEach((stage) => {
      stage.steps.forEach((step) => {
        map.set(step.id, { ...step, stageSlug: stage.slug });
      });
    });
    return map;
  }, [application?.workflow.stages]);
  const stagesMap = useMemo(() => {
    const map = new Map<string, WorkflowStage>();
    application?.workflow.stages.forEach((stage) => {
      map.set(stage.id, stage);
    });
    return map;
  }, [application?.workflow.stages]);
  // Refers to the current step and stage the workflow is at
  const currentWorkflowStep = stepsMap.get(application?.current_step_id || '');
  const currentWorkflowStage = stagesMap.get(application?.current_stage_id || '');

  // Refers to the step the user is currently viewing.
  const currentStep = currentStage?.steps.find((step) => step.slug === stepSlug);

  let linkedStep: WorkflowStepWithStageSlug | null = null;
  const renderAction = (action: FormBuilderAction): FormBuilderAction => {
    const linkProps: Pick<FormBuilderAction, 'destinationUrl' | 'active'> = {};
    let icon;
    let tooltip;
    let disabled = action.disabled;
    const isCurrentStepLink = currentStep?.id === action.overrideDestinationStepId;

    if (action.type === ACTION_LINK) {
      const step = stepsMap.get(action.overrideDestinationStepId);
      if (!step) {
        console.error(`Cannot find step ${action.overrideDestinationStepId} to generate action url`);
      } else {
        linkedStep = step;
        const linkedStage = stagesMap.get(step.stage_id);

        if (!linkedStage || !currentWorkflowStage) {
          console.error(`Cannot find stage linked to step ${step.id}`);
        } else {
          disabled =
            disabled ||
            !!step.locked ||
            // Disable all links to steps in stages after the current one.
            linkedStage.order > currentWorkflowStage.order ||
            // Disable all steps from current stage that come after current step
            (linkedStage.order === currentWorkflowStage.order && (currentWorkflowStep?.order || 0) < step.order);
        }
      }

      if (linkedStep) {
        linkProps.destinationUrl = makeLinkToStep(schoolSlug, applicantId, application!.id, linkedStep.stageSlug, linkedStep.slug);
      }

      if (isCurrentStepLink) {
        linkProps.active = true;
      }
    }

    if (action.type === ACTION_LINK && currentStageMessages.length && linkedStep) {
      const message = currentStageMessages.find((message) => message.step_id === linkedStep?.id);
      if (message) {
        icon = message.status;
        tooltip = content(message.translation_key, {}, message.message);
      }
    }

    return {
      ...action,
      ...linkProps,
      icon,
      tooltip,
      disabled,
    };
  };

  useEffect(() => {
    if (application) {
      setSubHeader(
        <ApplicationProvider id={application.id}>
          <WorkflowHeader stageSlug={stageSlug} />
        </ApplicationProvider>,
      );
    }
  }, [setSubHeader, currentStep, stageSlug, application]);

  let sidebarActions: FormBuilderAction[] = [];
  if (application && currentStage) {
    sidebarActions =
      getStepActionsForStage(currentStage)
        // TODO: update filter to filter on current party?
        .filter((a) => a.position === POS_STEPLIST)
        .map((action) => renderAction(action)) || [];
  }

  const SIDEBAR_BUTTON_WIDTH = '230px';
  const SIDEBAR_MARGIN = 1;
  const CONTENT_MARGIN = 1;
  const contentMarginLeft = SIDEBAR_MARGIN * 2 + CONTENT_MARGIN;

  return (
    <Grid container justifyContent="center">
      <Grid lg={12} xl={10}>
        {isMediumOrAbove && (
          <Stack direction="column" spacing={1} sx={{ pt: 2, mx: SIDEBAR_MARGIN, position: 'fixed', width: SIDEBAR_BUTTON_WIDTH }}>
            {application ? (
              <WorkflowActionList actions={sidebarActions} workflowEndpoint={`/schools/${schoolSlug}/applications/${application.id}`} />
            ) : (
              <Loader fullPage />
            )}
          </Stack>
        )}
        <Box pt={2} pl={{ xs: 0, md: SIDEBAR_BUTTON_WIDTH }} ml={{ xs: 0, md: contentMarginLeft }} height="100%">
          <WorkflowStep />
        </Box>
      </Grid>
    </Grid>
  );
};
