import { NOTE_LABELS } from 'notes/constants/NoteLabels';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isValid } from 'date-fns';
import { DATE_DASH_FORMAT } from 'app/constants/DateFormats';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { usePost } from 'hooks/usePost';

interface AddNoteFormValues {
  task: string | null;
  reminder: Date | null;
  content: string;
}

export const AddNoteDialog = ({ active, id, onToggle, recipient, withReminderDate, withTask }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const formDefaultValues: AddNoteFormValues = { content: '', task: withTask ? '' : null, reminder: null };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddNoteFormValues>({
    defaultValues: formDefaultValues,
  });
  const [isSaving, postNote] = usePost<ResponseEnvelope<any>>(`/schools/${schoolSlug}/${recipient}/${id}/notes`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      ...data,
      reminder: data.reminder ? format(data.reminder, DATE_DASH_FORMAT) : null,
    };

    try {
      const response = await postNote(payload);
      showSuccessMessage(response.message);
      onToggle();
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  });

  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Add note
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {withTask && (
            <Box mb={2}>
              <FormControl fullWidth error={!!errors.content}>
                <FormLabel id="taskLabel">Task</FormLabel>
                <Controller
                  control={control}
                  name="task"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select size="small" variant="outlined" aria-labelledby="taskLabel" value={value} onChange={onChange} error={!!errors.content}>
                        {NOTE_LABELS.map((noteLabel) => {
                          return (
                            <MenuItem key={noteLabel.value} value={noteLabel.value}>
                              {noteLabel.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    );
                  }}
                />
              </FormControl>
              {errors.task && <Typography color="error">{errors.task.message}</Typography>}
            </Box>
          )}
          {withReminderDate && (
            <>
              <FormControl fullWidth error={!!errors.reminder}>
                <FormLabel id="reminderLabel">Reminder date</FormLabel>
                <Controller
                  control={control}
                  name="reminder"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DatePicker
                        aria-labelledby="reminderLabel"
                        value={value}
                        minDate={new Date()}
                        slotProps={{ textField: { size: 'small', error: !!errors.reminder } }}
                        onChange={(newValue) => {
                          if (isValid(newValue)) {
                            onChange(newValue);
                          }
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
              {errors.reminder && <Typography color="error">{errors.reminder.message}</Typography>}
            </>
          )}
          <FormControl fullWidth error={!!errors.content}>
            <Controller
              name="content"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField multiline rows={3} value={value} onChange={onChange} error={!!errors.content} placeholder="Add new note here..." sx={{ mt: 2 }} />
              )}
            />
          </FormControl>
          {errors.content && <Typography color="error">{errors.content.message}</Typography>}
          <DialogActions sx={{ mt: 2, p: 0 }}>
            <LoadingButton variant="contained" type="submit" loading={isSaving}>
              Save note
            </LoadingButton>
            <Button variant="outlined" disabled={isSaving} onClick={onToggle} color="warning">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
